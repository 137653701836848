<template>
    <div class="app-download">
        <!-- 下载 -->
        <div class="header" :style="{background: `url(${appList.background_url}) center/cover  no-repeat`}">
            <div class="head-inner claerfix">
                <div class="download">
                    <p>
                        <a :href="appList.android_url" target="_block">
                            <img src="../../assets/images/安卓1.png" alt="">
                        </a>
                        <a :href="appList.ios_url" target="_block">
                            <img src="../../assets/images/苹果2.png" alt="">
                        </a>
                    </p>
                    <div>
                        <img :src="appList.qrcode" alt="">
                        <span>扫码下载客户端</span>
                    </div>
                </div>
                <img :src="appList.img_url" alt="">
            </div>
        </div>
        <!-- 列表展示 -->
        <div class="app-list">
            <options-item v-for="(item, index) in appList.items" :item='item' :key="index"  :buy='false'></options-item>
        </div>
    </div>  
</template>

<script>
import {mapActions, mapState} from 'vuex';
import OptionsItem from '../../components/optionsItem.vue';
export default {
    components: {
        OptionsItem
    },
    data() {
        return {
            
        };
    },
    created() {
        this.getApp();
    },
    computed: {
        ...mapState({
            appList: state => state.appDownload.appList
        })
    },
    methods: {
        ...mapActions({
            'getApp': 'getApp'
        })
    }
};
</script>
<style lang="sass">
@import "./APPDownload.scss"
</style>
