import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index.js';
import VideoPlayer from 'vue-video-player';
import iView from '../node_modules/iview';
import VueLazyLoad from 'vue-lazyload';


import './assets/css/common.scss';
import 'iview/dist/styles/iview.css';
import 'swiper/dist/css/swiper.css';
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';

Vue.use(VideoPlayer);
Vue.use(iView);
Vue.use(VueLazyLoad, {
    loading: '/assets/images/teacher_bg.jpg'
});

Vue.config.productionTip = false;

// 解决 ie10 select不能点击问题
if (window.HTMLElement) {
    if (Object.getOwnPropertyNames(HTMLElement.prototype).indexOf('dataset') === -1) {
        Object.defineProperty(HTMLElement.prototype, 'dataset', {
            get: function () {
                let attributes = this.attributes; // 获取节点的所有属性
                let name = [];
                let value = []; // 定义两个数组保存属性名和属性值
                let obj = {}; // 定义一个空对象
                for (let i = 0; i < attributes.length; i++) { // 遍历节点的所有属性
                    if (attributes[i].nodeName.slice(0, 5) === 'data-') { // 如果属性名的前面5个字符符合"data-"
                        // 取出属性名的"data-"的后面的字符串放入name数组中
                        name.push(attributes[i].nodeName.slice(5));
                        // 取出对应的属性值放入value数组中
                        value.push(attributes[i].nodeValue);
                    }
                }
                for (let j = 0; j < name.length; j++) { // 遍历name和value数组
                    obj[name[j]] = value[j]; // 将属性名和属性值保存到obj中
                }
                return obj; // 返回对象
            },
        });
    }
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
