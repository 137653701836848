/**  
 * @author xingminghui
 * @file 1对1
 */

import * as types from '../type';
import {oneToOne} from '../../api/index';
import {cookie} from '../../utils/index';
export const state = {
    oneToOneList: {}
};
export const actions = {
    // 获取1对1列表
    async getOneToOne({commit}, payload) {
        return await oneToOne({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.status === 'success') {
                payload(res.data);
                commit(types.GET_ONE_TO_ONE, res.data);
            }
            // return res;
        }).catch(err => {
            console.log(err);
        }); 
    }
   
};
export const mutations = {
    // 获取1对1列表
    [types.GET_ONE_TO_ONE](state, data) {
        state.oneToOneList = data || {};
    }
};
