<template>
    <div class="middle-school" v-if="juniorList">
        <div class="inner">
            <carousel-banner :bannersData='bannersData' :height="360"></carousel-banner>
            <!-- 6年教学体系优势 -->
            <div class="teaching-system" v-if="bannersData"
            :style="{background: `url(${plan.background_url}) center/cover no-repeat`}">
                <div class="system-inner" >
                    <div class="title">
                        <img v-if="plan.title" src="../../assets/images/蓝色对勾.png" alt="">
                        <span>{{plan.title}}</span>
                    </div>
                    <p v-html="plan.description"></p>
                    <!-- <p>领世六年一贯培养出真正帮助孩子进入更好的高中、最好的大学</p> -->
                    <img :src="plan.img_url" alt="">
                </div>
            </div>
            <!-- 配套教材 -->
            <div class="teaching-material">
                <div class="material-header" 
                :style="{background: `url(${book.background_url}) center/cover no-repeat`}">
                    <div class="head-inner">
                        <p class="english">{{book.subtitle}}</p>
                        <div class="title">{{book.title}}</div>
                    </div>
                </div>
                <div class="material-container clearfix">
                    <div class="materia-list" v-for="(item, index) in book.items" :key="index">
                        <div class="img">
                            <img :src="item.img_url" alt="">
                        </div>
                        <div class="container">
                            <p class="name">{{item.title}}</p>
                            <p class="description">{{item.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 课堂教学六步法 THE SCIENTIFIC CLASSROOM SIX TEACHING METHOD-->
            <div class="six-method">
                <div class="head-inner">
                    <p class="english">{{six_step.subtitle}}</p>
                    <div class="title">{{six_step.title}}</div>
                </div>
                <img :src="six_step.img_url" alt="">
            </div>
            <!-- 科学流程 -->
            <div class="step clearfix">
                <div class="step-inner" v-for="(item, index) in step.items" :key="index">
                    <img :src="item.img_url" alt="">
                    <div>
                        <div class="title">{{item.title}}</div>
                        <div class="description">{{item.description}}</div>
                    </div>
                </div>
                <span class="line"></span>
            </div>
             <!-- 问题不拖延及时解答疑惑 -->
            <div class="wechat-picture" v-if="resolve.items && resolve.items.length">
                <wechat-picture :resolve="resolve.items"></wechat-picture>
                <div class="title">{{resolve.title}}</div>
                <div class="subtitle">{{resolve.description}}</div>
            </div>
            <!-- 师资力量 -->
            <div class="teacher-group" v-if="teacher.groups && teacher.groups.length">
                <div class="group-header"
                :style="{background: `url(${teacher.background_url}) center/cover  no-repeat`}">
                    <div class="head-inner">
                        <p class="english">{{teacher.subtitle}}</p>
                        <div class="title">{{teacher.title}}</div>
                    </div>
                </div>
                <teacher-group :teachersData="teacher.groups"></teacher-group>
            </div>
        </div>
    </div>
</template>

<script>
import CarouselBanner from '../banner.vue';
import TeacherGroup from '../../components/teacherGroup.vue';
import WechatPicture from '../../components/wechatPicture.vue';
import {cookie} from '../../utils/index';
import {mapState, mapActions} from 'vuex';
export default {
    components: {
        TeacherGroup,
        WechatPicture,
        CarouselBanner
    },
    props: ['currentId'],
    data() {
        return {
            teacher: {},
            book: {},
            plan: {},
            resolve: {},
            six_step: {},
            step: {},
            bannersData: []
        };
    },
    created() {
        this.getBanners();
        this.getJunior();
    },
    watch: {
        juniorList(val) {
            this.teacher = val.teacher;
            this.book = val.book;
            this.plan = val.plan;
            this.resolve = val.resolve;
            this.six_step = val.six_step;
            this.step = val.step;
        },
        banners(val) {
            let bannersItem = null;
            if(val) {
                let id = this.currentId;
                bannersItem = val.filter(item => {
                    return item.category_id === id;
                });
                this.bannersData = bannersItem[0].banners;
            }
        }
    },
    computed: {
        ...mapState({
            juniorList: state => state.PEPCourse.juniorList,
            banners: state => state.common.banners
        })
    },
    methods: {
        ...mapActions(['getJunior', 'getBanners'])
    }
};
</script>

<style lang="scss">
@import './middleSchool.scss';
</style>