<template>
    <div id="contact-us">
        <carousel-banner :bannersData='bannersData' :height="361"></carousel-banner>
        <div class="container">
            <Title v-if="school.title">
                <p>{{school.subtitle}}</p>
                <h4>{{school.title}}</h4>
            </Title>
            <div class="campus-show" v-if="school&&school.areas">
                <div class="left">
                    <div v-for="(item,index) in school.areas" :key="index" 
                    :class="[currentArea===item.id?'campus current':'campus']">
                        <div class="area" @click="checkCampus(item.id)">
                            <img v-if="currentArea!==item.id" :src="require(`../../assets/images/blue.png`)" alt="">
                            <div>
                                {{item.name}}<span></span>
                            </div>
                        </div>
                        <div class="school">
                            <span v-for="(val,index) in item.schools" :key="index" 
                            :class="[currentSchool===val?'current':'']" @click="checkSchool(val)">{{val.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="right-content">
                        <!-- <img src="../../assets/images/area.png" alt=""> -->
                        <div class="school_bg" 
                        :style="{background: `url(${currentSchool.background_url}) center/cover no-repeat`}">
                            <img :src="currentSchool.main_img_url" class="school-big-pic" alt="">
                            <img :src="currentSchool.sub_img_url" class="school-small-pic" alt="">
                        </div>
                        <div class="msg">
                            <div class="school-name">
                                <div>{{currentSchool.name}}</div>
                            </div>
                            <div class="school-introduce" v-html="currentSchool.introduce"></div>
                        </div>
                    </div>
                    <!-- 地图 -->
                    <div id="allmap"></div>
                </div>
            </div>
        </div>
        <!-- 学习群 -->
        <div class="learning-group" v-if="group"
        :style="{background: `url(${group.background_url}) center/cover no-repeat`}">
            <div class="learn-inner">
                <div class="group-item" v-if="group.qq&&group.qq.name">
                    <div class="title"> 
                        <img :src="group.qq.img_url" alt="">
                        <span>QQ用户群</span>
                    </div>
                    
                    <div class="group">
                        <p v-for="(item, index) in group.qq.description" 
                        :key="index">{{item}}</p>
                    </div>
                </div>
                <div class="group-item" v-if="group.service&&group.service.name">
                    <div class="title"> 
                        <img :src="group.service.img_url" alt="">
                        <span>客服咨询</span>
                    </div>
                    <div class="QR-code">
                        <div v-for="(item,index) in group.service.qrcodes" :key="index">
                            <img :src="item.img_url" alt="">
                            <span>{{item.description}}</span>
                        </div>
                    </div>
                </div>
                <div class="group-item" v-if="group.wechat&&group.wechat.name">
                    <div class="title"> 
                        <img :src="group.wechat.img_url" alt="">
                        <span class="wechat">微信公众号</span>
                    </div>
                    <div class="QR-code">
                        <div v-for="(item,index) in group.wechat.qrcodes" :key="index" class="">
                            <img :src="item.img_url" alt="">
                            <span>{{item.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CarouselBanner from '../../components/banner.vue';
import Title from '../../components/title.vue';
import {cookie} from '../../utils/index.js';
import {mapState, mapActions} from 'vuex';
export default {
    data() {
        return {
            currentArea: null,
            currentSchool: {},
            school: {},
            bannersData: []
        };
    },
    components: {
        Title,
        CarouselBanner
    },
    created() {
        this.getBanners();
        this.currentArea = Number(cookie.get('LINGSHI_AREA')) || 110000;
        this.getContact(res => {
            this.school = res.school;
            if(cookie.get('LINGSHI_AREA')) {
                let area = res.school.areas.filter(item => {
                    return item.id === Number(cookie.get('LINGSHI_AREA'));
                });
                this.currentSchool = area[0].schools[0];
            }else{
                this.currentSchool = res.school.areas[0].schools[0];
            }
            this.$nextTick(function() {
                this.createdMap();
            });
        });
    },
    computed: {
        ...mapState({
            group: state => state.contactUs.group,
            banners: state => state.common.banners
        })
    },
    watch: {
        banners(val) {
            let bannersItem = null;
            if(val) {
                let id = cookie.get('LINGSHI_CATEGORIY_ID');
                bannersItem = val.filter(item => {
                    return item.category_id + '' === id;
                });
                this.bannersData = bannersItem[0].banners;
            }
        }
    },
    methods: {
        ...mapActions(['getContact', 'getBanners']),
        checkCampus(checkedCampus) {
            if(this.currentArea !== checkedCampus) {
                this.currentArea = checkedCampus;
                // 点击城市默认选中第一个校区
                this.school.areas.forEach(element => {
                    if(element.id === checkedCampus) {
                        this.currentSchool = element.schools[0];
                    }
                });
                this.createdMap();
            }else {
                // 可以收起 
            }
        },
        checkSchool(checkedSchool) {
            // 获取数据
            this.currentSchool = checkedSchool;
            this.createdMap();
        },
        createdMap() {
            // 百度地图API功能
            /*  global BMap BMapLib 
                BMAPLIB_TAB_SEARCH
                BMAPLIB_TAB_TO_HERE
                BMAPLIB_TAB_FROM_HERE 
                */
            let map = new BMap.Map('allmap');
            let poi = new BMap.Point(Number(this.currentSchool.longitude), Number(this.currentSchool.latitude));
            map.centerAndZoom(poi, 16);
            map.enableScrollWheelZoom();

            let content = this.currentSchool.introduce;
            // 创建检索信息窗口对象
            let searchInfoWindow = null;
            searchInfoWindow = new BMapLib.SearchInfoWindow(map, content, {
                title: this.currentSchool.name, // 标题
                width: 290, // 宽度
                // height: 105, // 高度
                panel: "panel", // 检索结果面板
                enableAutoPan: true, // 自动平移
                searchTypes: [
                    BMAPLIB_TAB_SEARCH, // 周边检索
                    BMAPLIB_TAB_TO_HERE, // 到这里去
                    BMAPLIB_TAB_FROM_HERE // 从这里出发
                ]
            });
            let marker = new BMap.Marker(poi); // 创建marker对象
            marker.enableDragging(); // marker可拖拽
            searchInfoWindow.open(marker);
            function open() {
                searchInfoWindow.open(marker);
            }
            if(marker.addEventListener) {
                marker.addEventListener("click", open, true);
            }else if(marker.attachEvent) {
                marker.attachEvent('onclick', open, false);
            }
            map.addOverlay(marker); // 在地图中添加marker
        }
    },
    mounted() {
    }
};
</script>

<style lang="scss">
@import './contactUs.scss';
</style>