<template>
    <div class="header clearfix">
        <div class="title">
            <slot>
                <!-- <p></p>
                <h4></h4> -->
            </slot>
            <span></span>
        </div>
        <img v-if="show" :src="require('../assets/images/swag.png')" alt="">
    </div>
</template>

<script>
export default {
    props: ['show'],
    data() {
        return {

        };
    }
};
</script>

<style lang="scss" scoped>
     .header {
        padding-top: 50px;
        .title {
            color: #2011aa;
            float: left;
        }
        .title p{
            font-weight: bold;
        }
        .title h4 {
            font-size: 18px;
            margin-top: 6px;
        }
        .title span {
            height: 3px;
            width: 80px;
            background: #2011aa;
            display: inline-block;
        }
        img {
            width: auto;
            height: 32px;
            float: right;
        }
    }
</style>