<template>
    <div id="app">
        <!-- 导航 -->
        <div id="nav" v-if="!lowBrowser">
            <div class="nav-head clearfix">
                <div class="logo clearfix">
                    <img src="./assets/images/logo.png" alt>
                </div>
                <ul class="header clearfix" v-if="categories.length">
                    <!-- @click.native="changeRouter(item.id)" -->
                    <router-link
                        v-for="item in categories"
                        :key="item.id"
                        tag="li"
                        :to="item.path"
                    >{{item.name}}</router-link>
                    <li class="live" @click="handleGoLive">直播课</li>
                </ul>
            </div>
        </div>
        <!-- 城市定位 -->
        <div class="current-city" v-if="cityList">
            <i-select v-model="currentCity" style="width:120px;" ref="selectOption">
                <i-option v-for="item in cityList" 
                @click.native="changeCurentCity(item)"
                :value="item.name" :key="item.id">
                {{ item.name }}<span></span>                            
                </i-option>
            </i-select>
        </div>

        <div class="app-container" v-if="!lowBrowser">
            <router-view class="view" v-if="isRouterAlive&&getArea"/>
            <!-- 底部链接 -->
            <div class="footer">
                <div class="friendship-link">
                    <p>友情链接</p>
                    <div>
                    <a
                        :href="[item.target_url?item.target_url:'#']"
                        v-for="(item) in links"
                        :key="item.name"
                        target="_blank"
                    >
                        <img :src="item.img_url" alt>
                    </a>
                    </div>
                </div>
                <div
                    class="footnotes"
                    v-if="footer"
                    :style="{background: `url(${footer.background_url}) center/cover no-repeat`}"
                >
                    <div class="remind">
                    <p>
                        <a
                        :href="[footer.items[0].target_url!==''?footer.items[0].target_url:'#']"
                        target="_blank"
                        >{{footer.items[0].name}}</a>
                        <a @click="privacy">{{footer.items[1].name}}</a>
                    </p>
                    </div>
                    <p>Copyright@2010 - 2016 lingshiedu.ALL Rights Reserved.领世培优版权所有</p>
                    <div class="police">
                    <a
                        href="https://beian.miit.gov.cn"
                        target="_block"
                    >京ICP备13052970号&nbsp;&nbsp;</a>
                    <img
                        src="https://tcs.teambition.net/thumbnail/111g19805f23b2a02ae5ad1a8e6b4cbea01a/w/20/h/20"
                        alt
                    >
                    <a
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010602040083"
                        target="_block"
                    >京公网安备 11010602040083号</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- IE版本低于10提示用户升级版本 -->
        <div class="low-browser" v-if="lowBrowser">
            <div class="tips-upgrade">
                很抱歉，您的浏览版本较低，本网站暂不支持。
                请使用最新版Chrome、360、搜狗、火狐、Safari、IE11、Edge等浏览器。感谢您的支持与理解。
            </div>
        </div>
    </div>
</template>

<script>
import {UI} from './utils/browser.js';
import {mapActions, mapState} from "vuex";
import {cookie} from "./utils/index.js";
import {Select} from 'iview';

let lowBrowser = false;
if (UI.browser === 'Internet Explorer' && UI.version.split('.')[0] <= 9
) {
    lowBrowser = true;
}

export default {
    name: "App",
    provide: function() {
        return {
            reload: this.reload
        };
    },
    data() {
        return {
            lowBrowser: lowBrowser,
            isRouterAlive: true,
            getArea: sessionStorage.getItem('REFRESH'),
            currentCity: "", // 当前城市
            checkCity: false,
            isShow: false,
            routers: [],
            cityList: [],
            province: null
        };
    },
    computed: {
        ...mapState({
            cityListData: state => state.common.cityList,
            links: state => state.common.links,
            footer: state => state.common.footer,
            categories: state => state.common.categories,
            city: state => state.common.city
        })
    },
    created() {
        this.load();
        /* global BMap */
        new BMap.LocalCity().get(this.getCurrentCity);
    },
    methods: {
        ...mapActions({
            getareas: "getareas",
            getCategories: "getCategories",
            getFooter: "getFooter",
            getLinks: "getLinks"
        }),
        changeCurentCity(item) {
            this.changeCity(item);
        },
        load() {
            this.getareas(res => {
                this.cityList = res;
                this.$nextTick(function() {
                    this.getCategories();
                    this.getFooter();
                    this.getLinks();
                    res.forEach(item => {
                        if (cookie.get("LINGSHI_AREA") === item.id + "") {
                            this.currentCity = item.name;
                        }
                    });
                });
            });
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function() {
                this.isRouterAlive = true;
            });
        },
        getCurrentCity(result) {
            if (result) {
                new BMap.Geocoder().getLocation(result.center, this.getLocation);
            } 
        },
        getLocation(address) {
            this.province = address.addressComponents.province;
            this.province = this.province.substr(0, this.province.length - 1);
            if(!sessionStorage.getItem('REFRESH') || !cookie.get('LINGSHI_AREA')) {
                this.setCity(this.province);
            }
        },
        checkedCity() {
            this.checkCity = !this.checkCity;
        },
        changeCity(item) {
            cookie.set("LINGSHI_AREA", item.id);
            this.currentCity = item.name;
            this.checkCity = false;
            this.reload();
        },
        setCity(city) {
            let cityId = null;
            switch (city) {
            case "河北":
                cityId = 130100;
                this.currentCity = '河  北';
                break;
            case "山西":
                cityId = 140100;
                this.currentCity = '山  西';
                break;
            case "广东":
                cityId = 440300;
                this.currentCity = '广  州';
                break;
            default:
                cityId = 110000;
                this.currentCity = '北  京';
                break;
            }
            cookie.set("LINGSHI_AREA", cityId);
            sessionStorage.setItem('REFRESH', 1);  
            this.getArea = true;
        },
        privacy() {
            this.isShow = false;
            this.$router.push("/privacy");
        },
        handleGoLive() { // 跳转网页版直播课
            window.open('https://www.lingshilive.com');
        }
    },
    destroyed() {
        if(!sessionStorage.getItem('REFRESH')) {
            window.onbeforeunload = function (e) {
                cookie.del('LINGSHI_CATEGORIY_ID');
                cookie.del('LINGSHI_AREA');
            };
        }
    }
};
</script>

<style lang="scss">
#app {
    font-family: "PingFangSC-Regular", "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 70px;
    box-sizing: border-box;
    // overflow: hidden;
    #nav {
        width: 100%;
        font-size: 14px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
        background: #ffffff;
        border-top: 4px solid #060e9e;
        box-shadow: 4px 0 14px 4px #dedfe1;
    }
    #nav .nav-head {
        width: 1100px;
        margin: 0 auto;
        height: 70px;
    }
    #nav .logo {
        width: 220px;
        height: 70px;
        float: left;
        margin-right: 26px;
        > img {
            width: 96px;
            height: auto;
            float: left;
            margin-top: 24px;
        }
    }
    #nav .header {
        padding: 15px 0;
        position: relative;
        li {
            float: left;
            text-align: center;
            width: 102px;
            font-size: 14px;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            color: #333333;
            &:hover {
                color: #fd5a00;
                cursor: pointer;
            }
        }
        li.router-link-active {
            background: #fd5a00;
            border-radius: 10px;
            color: #ffffff;
        }
        li.live{
            position: absolute;
            right: -102px;
            top: 14px;
        }
    }
    .app-container{
        height: auto;
		min-height: 100%;
        position: relative;
        padding-bottom: 420px;
        box-sizing: border-box;
        overflow: auto;
    }
    .footer {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        .friendship-link {
            width: 100%;
            text-align: center;
            background: #eeeeee;
            padding: 40px 0 60px 0;
            box-sizing: border-box;
            p {
                color: #08109f;
                margin-bottom: 40px;
                font-size: 24px;
            }
            div {
                width: 960px;
                margin: 0 auto;
                display: table;
            }
            div a{
                width: 210px;
                display: table-cell;
                word-break: break-all;
                vertical-align: middle;
                display: inline-block;
            }
        }
        .footnotes {
            text-align: center;
            color: #ffffff;
            height: 180px;
            .remind {
                padding: 46px 0 36px;
            }
            .remind a {
                width: 224px;
                text-align: center;
                display: inline-block;
                font-weight: bold;
                font-size: 18px;
                position: relative;
                color: #ffffff;
            }
            .remind a:nth-child(1):after {
                content: "|";
                font-weight: normal;
                position: absolute;
                right: -2px;
            }
            > p {
                line-height: 28px;
                font-size: 14px;
            }
            .police {
                p {
                    display: inline;
                    margin-right: 10px;
                }
                img {
                    vertical-align: middle;
                    margin-right: 4px;
                }
                a {
                    color: #ffffff;
                    &:hover {
                        color: rgb(97, 161, 212);
                    }
                }
            }
        }
    }
    .current-city {
         position: fixed;
         top: 24px;
         left: 50%;
         margin-left: -450px;
         z-index: 40;
        .ivu-select-arrow{
            color: #4e4e4e;
            right: 0;
            margin-top: -2px;
            transform: rotate(0);
            transform: translateY(-50%);
        }
        .ivu-select{
            position: relative;
            height: 32px;
        }
        .ivu-icon-ios-arrow-down:before{
            content: '[切换城市]';
            font-family: "PingFangSC-Regular", "Avenir", Helvetica, Arial, sans-serif;
        }
        .ivu-select-selection{
            border: none;
        }
        .ivu-select-selected-value{
            padding: 0;
            position: absolute;
            right: 72px;
            top: 0;
            color: #4e4e4e;
            font-size: 14px;
        }
        .ivu-select-visible .ivu-select-selection{
            box-shadow: inherit;
        }
        .ivu-select-dropdown{
            z-index: 20;
            padding: 0;
        }
        .ivu-select-dropdown-list{
            padding: 0;
        }
        .ivu-select-dropdown .ivu-select-item{
            line-height: 32px;
            font-size: 14px !important;
            // display: block;
            width: 44px;
            height: 32px;
            padding: 0;
            font-weight: normal;
            margin-left: 40px;
            background: #ffffff;
            text-align: justify ;
            text-align-last: justify;
            text-justify: inter-ideograph;
            white-space: normal;
            // display: block;
        }
        .ivu-select-dropdown .ivu-select-item span{
            display: block;
            // width: 100%;
            padding-left: 100%;
        }   
        .ivu-select-dropdown .ivu-select-item-focus{
            background: none;
        }
    }
    //提醒升级
    .low-browser{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ffffff;
        z-index: 999;
        .tips-upgrade{
            width: 600px;
            height: 42px;
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 18px;
            margin-left: -300px;
            margin-top: -42px;
        }
    }
}
</style>