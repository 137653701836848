<template>
    <div class="privacy-protection">
        <img src="https://resource.lingshilive.com/website/9d17853c7bb9fbabb5ca03f46cfb7621.jpg" alt="">
        <ul class="privacy">
            <li>北京领世瑞丰教育科技有限公司对其发行的或与合作公司共同发行的包括但不限于产品或服务的全部内容及网站上的材料拥有版权等知识产权，受法律保护。</li>
            <li>未经本公司书面许可，任何单位及个人不得以任何方式或理由对上述产品、服务、信息、材料的任何部分进行使用、复制、修改、抄录、传播或与其它产品捆绑使用、销售。</li>
            <li>凡侵犯本公司版权等知识产权的，本公司必依法追究其法律责任。</li>
            <li>本公司法律事务部受本公司指示，特此郑重法律声明！</li>
            <li>声明单位：北京领世瑞丰教育科技有限公司法律事务部和对用户密码加密，防止您的个人资料丢失、被盗用或遭窜改。尽管我们有这些安全措施，但请注意在因特网上不存在“完善的安全措施”。</li>
        </ul>
    </div>
</template>

<script>
export default {
        
};
</script>

<style lang="scss" scoped>
.privacy-protection{
    width: 100%;
    img{
        width: 100%;
    }
    .privacy{
        min-height: 500px;
        margin: 30px auto;
        width: 960px;
        li{
            color: #8f8f8f;
            font-size: 16px;
            line-height: 30px;
            padding-left: 25px;
            margin-bottom: 20px;
            position: relative;
            &:after{
               content: '';
               width: 11px;
                height: 11px;
                display: block;
                border-radius: 50%;
                background: #eb341c;
                position: absolute;
                top: 10px;
                left: 0;
            }
        }
    }
}
</style>