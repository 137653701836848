<template>
    <div id="index">
        <!-- banner -->
        <div class="banner" v-if="bannerList.length===1">
                <img class="carousel" 
                @click="goBannerUrl(bannerList[0].target_url)" 
                :src="bannerList[0].img_url" alt="">
        </div>
        <Carousel v-model="value" loop v-if="bannerList.length>1"
        :autoplay-speed='speed' 
        :radius-dot='true' 
        :autoplay='true' 
        :arrow="arrow" id="banner">
            <CarouselItem v-for="(item,index) in bannerList" :key="index" 
            @click.native="goBannerUrl(item.target_url)">
                <img class="demo-carousel" :src="item.img_url" alt="">
            </CarouselItem>
        </Carousel>
        <!-- 最科学的教学规划体系  THE MOST SCIENTIFIC PLANNING -->
        <div class="teaching-plan" v-if="system&&system.title">
            <p class="english">{{system.subtitle}}</p>
            <div class="title">{{system.title}}</div>
            <p class="describe">{{system.description}}</p>
            <div class="system">
                <div class="system-item" v-for="(item, index) in system.items" :key="index" 
                @click="goUrl(item.target_url)">
                    <div class="top">
                        <img :src="item.background_url" alt="">
                        <div class="masker"></div>
                        <span>{{item.name}}</span>
                    </div>
                    <div class="content">
                        <div class="tags">
                            <span class="tag" v-for="(val, index) in item.grades" :key="index">{{val}}</span>
                        </div>
                        <p>{{item.description}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 最贴心的的教学服务 THE MOST INTIMATE SERVICE-->
        <div class="intimate-service" v-if="service">
            <div class="theme"
            :style="{background: `url(${service.background_url}) center/cover  no-repeat`}"> 
                <p class="english">{{service.subtitle}}</p>
                <div class="title">{{service.title!==null&&service.title}}</div>
            </div>
            <div class="service-options">
                <div class="option-icon" >
                    <div v-for="(item,index) in service.items" :key="index">
                        <img :src="item.img_url" alt="">
                    </div>
                </div>
                <div class="option-title clearfix">
                    <h4 v-for="(item,index) in service.items" :key="index">{{item.title}}</h4>
                </div>
                <div class="option-content clearfix">
                    <p v-for="(item,index) in service.items" :key="index">{{item.description}}</p>
                </div>
            </div>
            <img :src="service.six_step_img_url" alt="">
        </div>
        <!-- 强大师资  THE MIGHTY TEACHING STAFF-->
        <div class="teaching-staff" v-if="teacher">
            <div class="teaching-inner" v-if="teacher.title">
                <p class="english">{{teacher.subtitle}}</p>
                <div class="title">{{teacher.title}}</div>
                <p class="describe">{{teacher.description}}</p>
                <div class="teaching-swiper" v-if="teacher.teachers.length">
                    <div class="swiper-container" id="swiper-teachers">
                        <div class="swiper-wrapper">
                            <div v-for='(el,index) in teacher.teachers' :key='index' class="swiper-slide">
                                <img :src="el.img_url" alt="" class="teacher-photo">
                                <div class="information">
                                    <span class="project">{{el.subject_name}}</span>
                                    <img :src="el.subject_english_img_url" alt="" class="project-bg">
                                    <h4 class="teacher-name">{{el.name}}</h4>
                                    <p>{{el.description}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- 如果需要分页器 -->
                        <div class="swiper-pagination"></div>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 视频课程 THE LATEST VIDEO-->
        <div class="video-course" v-if="video">
            <div class="video-inner" v-if="video.title">
                <p class="english">{{video.subtitle}}</p>
                <div class="title">{{video.title}}</div>
                <!-- <p class="describe">按照学生的不同需求和不同层次，讲义完全分开，授课节奏和授课方式也完全不同</p> -->
                <div class="video-swiper" v-if="video.items">
                    <div class="swiper-container" id="swiper-video">
                        <div class="swiper-wrapper">
                            <div v-for='(el,index) in video.items' :key='index' class="swiper-slide"
                                @click="showVideo(index)" > 
                                <div class="video-img">
                                    <img :src="el.img_url" class='url' alt="">
                                    <img src="../../assets/images/视频播放icon.png" class="video" alt="">
                                </div>
                                <div class="teacher clearfix">
                                    <div class="left">
                                        <img src="../../assets/images/老师icon.png" alt=""> {{el.teacher_name}}
                                    </div>
                                    <div class="right">
                                        <img src="../../assets/images/数学icon.png" alt=""> {{el.subject_name}}
                                    </div>
                                </div>
                                <p class="content">{{el.title}}</p>
                            </div>
                        </div>
                        <!-- 如果需要分页器 -->
                        <div class="swiper-pagination"></div>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 领世教研精品 THE CHOICEST ARTICLES -->
        <div class="choicest-articles" v-if="product">
            <div class="choicest-header"
            :style="{background: `url(${product.background_url}) center/cover  no-repeat`}">
                <div class="header-inner">
                    <p class="english">{{product.subtitle}}</p>
                    <div class="title">{{product.title}}</div>
                    <p class="describe" v-html='product.description'>
                        <!-- <span>最豪华的教材研发团队：</span> 
                        按照学生的不同需求和不同层次，讲义完全分开， 授课节奏和授课方式也完全不同按照学生的不同需求和不同层次，讲义完全分开，
                         授课节奏和授课方式也完全不同按照学生的不同需求和不同层次，讲义完全分开， 
                        授课节奏和授课方式也完全不同按照学生的不同需求和不同层次， 
                        讲义完全分开，授课节奏和授课方式也完全不同按照学生的不同需求和不同层次，
                        讲义完全分开，授课节奏和授课方式也完全不同 -->
                    </p>
                </div>
            </div>
            <div class="choicest-swiper-bg" v-if="product.images">
                <div class="choicest-swiper">
                    <div class="swiper-container" id="choicest-articles" >
                        <div class="swiper-wrapper">
                            <div v-for='(el,index) in product.images' :key='index' class="swiper-slide">
                                <a :href="el.target_url?el.target_url:'javascript:void(0);'" 
                                :target="el.target_url?'_blank':''">
                                    <img :src="el.img_url" alt="" class="book-photo">
                                </a>
                            </div>
                        </div>
                        <!-- 如果需要分页器 -->
                        <!-- <div class="swiper-pagination"></div> -->
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 视频 -->
        <div class="player" v-if="watchVideo">
            <div class="close-video" @click="closeVideo">
                <img src="../../assets/images/close.png" alt="">
            </div>
            <video-player class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            ></video-player>
        </div>
        <!-- 侧边咨询 官方微信 -->
        <div class="asides-code" v-if="qrcode">
            <div class="aside-item clearfix" @mouseleave="hideCode">
                <div v-if="show" class="aside-code" >
                    <div class="code-item" v-for="(item, index) in qrcode.consult" :key="index">
                        <img :src="item.url" alt="">
                    </div>
                    <div class="border-right-empty">
                        <span></span>
                    </div>
                </div>
                <div @mouseover="showCode" class="message">
                    <img src="../../assets/images/电话.png" alt="">
                    <span>在线咨询</span>
                </div>
            </div>
            <div class="aside-item clearfix" @mouseleave="hideWechat">
                <div v-if="wechat" class="aside-code">
                    <!-- <img v-for="(item, index) in qrcode.wechat" :key="index" :src="item.url" alt=""> -->
                    <div class="code-item" v-for="(item, index) in qrcode.wechat" :key="index">
                        <img :src="item.url" alt="">
                    </div>
                    <div class="border-right-empty">
                        <span></span>
                    </div>
                </div>
                <div @mouseover="showWechat" class="message">
                    <img src="../../assets/images/微信.png" alt="">
                    <span>官方微信</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Carousel} from 'iview';
import Swiper from 'swiper';
import {mapActions, mapState} from 'vuex';
import {cookie} from '../../utils/index.js';
let timeOut = null;
export default {
    inject: ['reload'],
    components: {
        Carousel
    },
    data() {
        return {
            value: 0,
            arrow: 'always',
            speed: 5000,
            screenWidth: document.body.clientWidth, // 屏幕宽度
            timer: false,
            styleObject:{
                height: ''
            },
            watchVideo: false,
            wechat: false, 
            show: false, 
            bannerList: [],
            teacher: {},
            product: {},
            video: {},
            playerOptions: {
                // playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: true, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",
                    src: "" // 你的m3u8地址（必填）
                }],
                poster: "poster.jpg", // 你的封面地址
                width: document.documentElement.clientWidth,
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            },
        };
    },
    created() {
        let id = cookie.get('LINGSHI_CATEGORIY_ID');
        if(id === '') {
            id = 1;
            cookie.set('LINGSHI_CATEGORIY_ID', id);
        }
        let _this = this;
        this.getqrcode();
        this.getBanners();
        this.getHomePage({area: cookie.get('LINGSHI_AREA'), getData: res => {
            _this.teacher = res.teacher;
            _this.product = res.product;
            _this.video = res.video;
            _this.$nextTick(function() {
                _this.creactedSwiper('#swiper-video', false, 4, 1, 0);
                _this.creactedSwiper('#swiper-teachers', true, 2, 2, 60);
                _this.creactedBookSwiper('#choicest-articles', true, 4, 1, 0);
            });
        }});
        this.styleObject.height = 680 / (1920 / this.screenWidth) + 'px'; 
    },
    computed: {
        ...mapState({
            qrcode: state => state.common.qrcode,
            banners: state => state.common.banners,
            system: state => state.homePage.system,
            service: state => state.homePage.service,
        })
    },
    watch: {
        banners(val) {
            let id = cookie.get('LINGSHI_CATEGORIY_ID');
            val.forEach(item => {
                if(item.category_id === Number(id)) {
                    this.bannerList = item.banners;
                    this.$nextTick(function() {
                        this.creactedSwiper('#swiper-video', false, 4, 1, 0);
                        this.creactedSwiper('#swiper-teachers', true, 2, 2, 60);
                        this.creactedBookSwiper('#choicest-articles', true, 4, 1, 0);
                    });
                }
            });
        },
        screenWidth (val) {
            if (!this.timer) {
                this.screenWidth = val;
                this.styleObject.height = 680 / (1920 / val) + 'px'; 
                this.timer = true;
                let that = this;
                timeOut = setTimeout(function () {
                    that.timer = false;
                }, 400);
            }
        }
    },
    methods: {
        ...mapActions(['getBanners', 'getHomePage', 'getqrcode']),
        showCode() {
            this.show = true;
        },
        hideCode() {
            this.show = false;
        },
        showWechat() {
            this.wechat = true;
        },
        hideWechat() {
            this.wechat = false;
        },
        goBannerUrl(url) {
            if(url) {
                window.open(url, '_blank');
            }
        },
        // 最科学的教学体系跳转链接
        goUrl(url) {
            if(url) {
                window.open(url, '_blank');
            }
        },
        creactedSwiper(swiperName, loopData, slidesPerView, slidesPerGroup, between) {
            new Swiper(swiperName, {
                loop: loopData, // 循环模式选项
                slidesPerView: slidesPerView,
                spaceBetween: between || 0,
                slidesPerGroup: slidesPerGroup,
                loopFillGroupWithBlank: true,
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function(index, className) {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    }
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });
        },
        showVideo(index) {
            this.playerOptions.sources[0].src = this.video.items[index].video_url;
            this.$nextTick(function() {
                this.watchVideo = true;
            });
        },
        closeVideo() {
            this.watchVideo = false;
        },
        creactedBookSwiper(swiperName, loopData, slidesPerView, slidesPerGroup, between) {
            new Swiper(swiperName, {
                loop: true, // 循环模式选项
                slidesPerView: slidesPerView,
                spaceBetween: between || 0,
                slidesPerGroup: slidesPerGroup,
                loopFillGroupWithBlank: true,
                observer: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });
        },
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth;
            })();
        };
    },
    destroyed() {
        clearTimeout(timeOut);
    }
};
</script>

<style lang="scss">
@import './index.scss';
.player{
    width: 50%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 40;
    .vjs-custom-skin > .video-js .vjs-big-play-button{
        display: none;
    }
    .vjs-icon-play:before, .video-js .vjs-big-play-button .vjs-icon-placeholder:before{
        content: "";
    }
}
.close-video{
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    top: 0;
    right: -40px;
    z-index: 60;
    background: #1b1b1b;
   
}
.close-video img{
    display: inline-block;
    margin-top: 6px;
}
</style>