/**  
 * @author xingminghui
 * @file 培优课程
 */

import * as types from '../type';
import {senior, junior} from '../../api/index';
import {cookie} from '../../utils/index';
export const state = {
    seniorList: {},
    juniorList: {},
    currentGrade: 8
};
export const actions = {
    // 获取1对1列表
    async getSenior({commit}, payload) {
        return await senior({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.status === 'success') {
                payload(res.data);
                commit(types.GET_SENIOR, res.data);
            }
            return res;
        }).catch(err => {
            console.log(err);
        }); 
    },
    async getJunior({commit}) {
        return await junior({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.status === 'success') {
                commit(types.GET_JUNIOR, res.data);
            }
            return res;
        }).catch(err => {
            console.log(err);
        }); 
    },
    changeGrade({commit}, payload = {}) {
        commit(types.CHANGE_CURRENT_GRADE, payload);
    }
};
export const mutations = {
    // 获取1对1列表
    [types.GET_SENIOR](state, data) {
        state.seniorList = data || {};
    },
    [types.GET_JUNIOR](state, data) {
        state.juniorList = data || {};
    },
    [types.CHANGE_CURRENT_GRADE](state, data) {
        state.currentGrade = data;
    }
};
