<template>
    <div class="autonomous-enrollment" v-if="recruitList">
        <carousel-banner :bannersData='bannersData' :height="478"></carousel-banner>
        <!-- 视频 -->
        <div class="player-video" v-if="playerOptions.sources[0].src">
            <!-- <span class="close"></span> -->
            <video-player class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
            ></video-player>
       </div>
       <!-- 侧边二维码 -->
        <div class="asides-code">
            <div class="aside-item clearfix" @mouseleave="hideConsultation">
                <div v-if="show" class="aside-code" >
                    <div class="code-item" v-for="(item, index) in sideQRcode.consult" :key="index">
                        <img :src="item.url" alt="">
                    </div>
                    <div class="border-right-empty">
                        <span></span>
                    </div>
                </div>
                <!-- <img v-if="show" src="../../assets/images/北京.png" alt=""> -->
                <div @mouseover="showConsultation" class="message">
                    <img src="../../assets/images/电话.png" alt="">
                    <span>在线咨询</span>
                </div>
            </div>
            <div class="aside-item clearfix" @mouseleave="hideWechat">
                <!-- <img src="../../assets/images/官方微信.png" alt="" v-if="wechat"> -->
                 <div v-if="wechat" class="aside-code" >
                    <div class="code-item" v-for="(item, index) in sideQRcode.wechat" :key="index">
                        <img :src="item.url" alt="">
                    </div>
                    <div class="border-right-empty">
                        <span></span>
                    </div>
                </div>
                <div @mouseover="showWechat" class="message">
                    <img src="../../assets/images/微信.png" alt="">
                    <span>官方微信</span>
                </div>
            </div>
        </div>
        <!-- 名校直通计划 THE FAMOUS SCHOOL THROUGH PLAN-->
        <div class="through-plan" v-if="plan">
            <Title :show='true' v-if="plan.title">
                <p>{{plan.subtitle}}</p>
                <h4>{{plan.title}}</h4>
            </Title>
            <div class="introduce" :style="{background: `url(${plan.background_url}) center/cover  no-repeat`}">
                <p>{{plan.description}}</p>
            </div>
            <div class="steps" v-if="plan.items">
                <img class="blue-bg" src="../../assets/images/自主招生/名校直通车蓝色.png" alt="">
                <img class="orange-bg" src="../../assets/images/自主招生/名校直通车图标橙色.png" alt="">
                <div class="step-container">
                    <div class="step" v-for="(item, index) in plan.items" :key="index" 
                    @click="checkStap(index)">
                        <div class="step-inner clearfix">
                            <span class="title">{{item.title}}</span>
                            <img :src="item.icon_url" alt="">
                            <span class="sort">{{index+1}}</span>
                        </div>
                         <div class="description" v-if="current===index" @click.stop="closeStep">
                            {{item.description}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <!-- 适合人群 -->
        <div class="suit-crowds gift" v-if="crowd"
        :style="{background : `url(${crowd.background_url}) center/cover no-repeat`}">
            <div class="head" v-if="crowd.title">
                <div class="english">{{crowd.subtitle}}</div>
                <p class="title">{{crowd.title}}</p>
            </div>
            <div class="suit-list">
                <div class="list-item clearfix" v-for="(item, index) in crowd.items" :key="index">
                    <div class="top clearfix">
                        <p>
                            <span>{{item.title}}</span>
                            {{item.subtitle}}
                        </p>
                        <img :src="item.icon_url" alt="">
                    </div>
                    <p class="bottom">{{item.content}}</p>
                </div>
            </div>
        </div>
        <!-- 全方位专业测评 -->
        <div class="evaluation" v-if="evaluation">
            <div class="evaluation-header" v-if="evaluation.title">
                <Title :show='false'>
                    <p>{{evaluation.subtitle}}</p>
                    <h4>{{evaluation.title}}</h4>
                </Title>
            </div>
            
            <img :src="evaluation.img_url" alt="">
        </div>
        <!-- 顶级菁英规划团队 -->
        <div class="top-team" v-if="group">
            <div class="evaluation-header" v-if="group.title">
                 <Title :show='false'>
                    <p>{{group.subtitle}}</p>
                    <h4>{{group.title}}</h4>
                </Title>
            </div>
            <img :src="group.img_url" alt="">
        </div>
        <!-- 线上综评自招随时看  支持多条-->
        <div class="anywhere-watch" v-if="online">
            <div class="evaluation-header" v-if="online.title">
                <Title :show='false'>
                    <p>{{online.subtitle}}</p>
                    <h4>{{online.title}}</h4>
                </Title>
            </div>
            <div v-if="online.items">
              <OptionItem v-for="(item, index) in online.items" :key="index" 
              :item='item' :buy='true' @showQrcode="showQrcode(item.qrcode)"></OptionItem>  
            </div>
            <div class="watch-masker" v-if="showWatchCode">
                <div class="position">
                    <img class="close" src="../../assets/images/orange_close.png" alt=""
                    @click="closeWatchCode">
                    <img class="code" :src="onLineQrcode" alt="">  
                    <!-- 暂时未用接口 -->
                    <!-- <img class="code" src="../../assets/images/二维码的副本.png" alt=""> -->
                </div>
            </div>
        </div>
        <!-- 综合评价与自主招生笔面试课程与全套资料书籍 -->
        <div class="books" v-if="book">
            <div class="evaluation-header" v-if="book.title">
                <Title :show='false'>
                    <p>{{book.subtitle}}</p>
                    <h4>{{book.title}}</h4>
                </Title>
            </div>
            <div class="books-wrap" v-if="book.items">
                <div class="swiper-container" id="books-swiper" >
                    <div class="swiper-wrapper" >
                        <div v-for='(el,index) in book.items' :key='index' class="swiper-slide">
                            <img :src="el.img_url" alt="" class="book-photo">
                        </div>
                    </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-button-prev">
                    <img src="../../assets/images/left.png" alt="">
                </div>
                <div class="swiper-button-next">
                    <img src="../../assets/images/right.png" alt="">
                </div>
            </div>
            <div class="masker" v-if="isCode">
                <div class="position">
                    <img class="close" src="../../assets/images/orange_close.png" alt=""
                    @click="closeCode">
                    <img class="code" :src="qrcode" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CarouselBanner from '../../components/banner.vue';
import Title from '../../components/title.vue';
import OptionItem from '../../components/optionsItem.vue';
import {cookie} from '../../utils/index.js';
import {mapState, mapActions} from 'vuex';
import Swiper from 'swiper';
export default {
    components: {
        Title,
        OptionItem,
        CarouselBanner
    },
    data() {
        return {
            current: null,
            show: false,
            wechat: false,
            book: {},
            crowd: {},
            evaluation: {},
            group: {},
            plan: {},
            realIndex: 0,
            qrcode: '', 
            onLineQrcode: '',
            isCode: false,
            showWatchCode: false, 
            playerOptions: {
                // playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: true, // 如果true,浏览器准备好时开始回放。
                muted: true, // 默认情况下将会消除任何音频。
                loop: true, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",
                    src: "" // 你的m3u8地址（必填）
                }],
                poster: "poster.jpg", // 你的封面地址
                width: document.documentElement.clientWidth,
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            },
            bannersData: []
        };
    },
    created() {
        this.getBanners();
        this.getQRcode();
        this.getRecruit(res => {
            this.playerOptions.sources[0].src = res.video_url;
            this.book = res.book;
            this.$nextTick(function() {
                this.creactedSwiper('#books-swiper');
            });
        });
    },
    computed: {
        ...mapState({
            recruitList: state => state.autonomousEnrollment.recruitList,
            online: state => state.autonomousEnrollment.online,
            banners: state => state.common.banners,
            sideQRcode: state => state.autonomousEnrollment.sideQRcode
        })
    },
    watch: {
        recruitList(val) {
            this.crowd = val.crowd;
            this.evaluation = val.evaluation;
            this.group = val.group;
            this.plan = val.plan;
        },
        banners(val) {
            let bannersItem = null;
            if(val) {
                let id = cookie.get('LINGSHI_CATEGORIY_ID');
                bannersItem = val.filter(item => {
                    return item.category_id + '' === id;
                });
                this.bannersData = bannersItem[0].banners;
            }
        }
    },
    methods: {
        ...mapActions(['getRecruit', 'getBanners', 'getQRcode']),
        creactedSwiper(swiperName) {
            let _this = this;
            new Swiper(swiperName, {
                loop: true, // 循环模式选项
                on: {
                    click: function () {
                        // 这里有坑   左右按钮需要放到 container外部 否则点击也会触发。
                        // 需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法 
                        // console.log(this); // -> Swiper
                        // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。           
                        _this.realIndex = this.realIndex;
                        _this.showCode();
                    }
                },
                observer:true,
                preventLinksPropagation: false, // 拖动Swiper时阻止click事件。
                // 如果需要分页器
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });
        },
        checkStap(id) {
            this.current = id;
        },
        closeStep() {
            this.current = null;
        },  
        showConsultation() {
            this.show = true;
        },
        hideConsultation() {
            this.show = false;
        },
        showWechat() {
            this.wechat = true;
        },
        hideWechat() {
            this.wechat = false;
        },
        showCode() {
            this.qrcode = this.book.items[this.realIndex].qrcode;
            this.isCode = true;
        },
        closeCode() {
            this.isCode = false;
        },
        closeWatchCode() {
            this.showWatchCode = false;
        },
        showQrcode(codeUrl) {
            this.showWatchCode = true;
            this.onLineQrcode = codeUrl;
        }
    }
};
</script>

<style lang="scss">
@import './AutonomousEnrollment.scss';
.player-video .vjs-custom-skin > .video-js .vjs-big-play-button{
    display: none;
}
.player-video .video-js.vjs-ended .vjs-big-play-button{
    display: none !important;
}
</style>