<template>
    <div class="high-school">
        <carousel-banner :bannersData='bannersData' :height="360"></carousel-banner>
        <!-- 培优课程 -->
        <div class="teaching-system"
        :style="{background: `url(${course.background_url}) center/cover no-repeat`}">
            <div class="system-inner">
                <div class="title">
                    <img v-if="course.title" src="../../assets/images/蓝色对勾.png" alt="">
                    <span>{{course.title}}</span>
                </div>
                <p>{{course.description}}</p>
                <img :src="course.img_url" alt="">
            </div>
        </div>
        <!-- 列表循环 -->
        <div class="list">
            <option-item v-for="(item, index) in course.items" :key="index" :item='item' />
        </div>
        <img class="book" :src="books.img_url" alt="">
        <!-- 完善de教学体系 -->
        <div class="service-system">
            <div class="title">{{step.title}}</div>
            <div class="service-swiper">
                <div class="theme">
                    <span class="blue">领世菁英体系学员</span>
                    <span class="gray">大部分高中生</span>
                </div>
                <div class="plan-swiper">
                    <div class="swiper-container" id="swiper-plan">
                        <div class="swiper-wrapper">
                            <div v-for='(el,index) in step.items' :key='index' class="swiper-slide">
                                <img :src="el" alt="" class="teacher-photo">
                            </div>
                        </div>
                        <!-- 如果需要分页器 -->
                        <div class="swiper-pagination" id="plan-pagination"></div>
                        <div class="swiper-button-prev">
                            <img src="../../assets/images/左.png" alt="">
                        </div>
                        <div class="swiper-button-next">
                            <img src="../../assets/images/右.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 问题不拖延及时解答疑惑 -->
        <div class="wechat-picture" v-if="resolve.items && resolve.items.length">
            <wechat-picture :resolve="resolve.items"></wechat-picture>
            <div class="title">{{resolve.title}}</div>
            <div class="subtitle">{{resolve.description}}</div>
        </div>
        <!-- 师资团队 -->
        <div class="teacher-team" v-if="teacher.groups && teacher.groups.length">
           <div class="team-header" 
           :style="{background: `url(${teacher.background_url}) center/cover  no-repeat`}">
                <div class="head-inner">
                    <div class="title">
                        <img src="../../assets/images/选择框.png" alt="">
                        <h4>{{teacher.title}}</h4>
                    </div>
                    <p class="discription">{{teacher.description}}</p>
                </div>
            </div>
            <!-- 师资 组件 -->
            <teacher-group :teachersData="teacher.groups"></teacher-group>
        </div>
    </div>
</template>

<script>
import CarouselBanner from '../banner.vue';
import OptionItem from '../../components/optionsItem';
import TeacherGroup from '../../components/teacherGroup.vue';
import WechatPicture from '../../components/wechatPicture.vue';
import {cookie} from '../../utils/index';
import Swiper from 'swiper';
import {mapState, mapActions} from 'vuex';
export default {
    inject: ['reload'],
    components: {
        OptionItem, 
        TeacherGroup,
        WechatPicture,
        CarouselBanner
    },
    props: ['currentId'],
    data() {
        return {
            books: {},
            course: {},
            teacher: {},
            step: {},
            resolve: {},
            bannersData: []
        };
    },
    created() {
        this.getBanners();
        this.getSenior(res => {
            this.step = res.step;
            this.$nextTick(function() {
                this.creactedSwiper('#swiper-plan');
            });
        });
    },
    computed: {
        ...mapState({
            seniorList: state => state.PEPCourse.seniorList,
            banners: state => state.common.banners
        })
    },
    watch: {
        seniorList(val) {
            this.books = val.books;
            this.course = val.course;
            this.teacher = val.teacher;
            this.step = val.step;
            this.resolve = val.resolve;
        },
        banners(val) {
            let bannersItem = null;
            let id = this.currentId;
            bannersItem = val.filter(item => {
                return item.category_id === id;
            });
            this.bannersData = bannersItem[0].banners;
        }
    },
    methods: {
        ...mapActions(['getSenior', 'getBanners']),
        creactedSwiper(name) {
            new Swiper(name, {
                autoplay: true,
                loop: true,
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });
        }
    },
    mounted() {
        this.creactedSwiper('#swiper-plan');
    }
};
</script>

<style lang="scss">
@import './highSchool.scss'
</style>