<template>
    <div class="appointment-wrap" v-if="isShow">
        <div class="appointment-inner" v-if="current">
            <img class="close" @click="closeAppointment" src="../assets/images/close.png" alt="">
            <img src="../assets/images/appointment_bg.png" class="bg" alt="">
            <div class="appointment-container">
                <div class="header">预约一对一咨询</div>
                <div class="title">领世1对1</div>
                <div class="name-wrap">
                    <input type="text" maxlength="20" placeholder="您孩子的姓名" v-model="name">
                    <p class="name-tips" v-if="nameTips">{{nameTip}}</p>
                </div>
                <div class="phone-wrap">
                    <input type="text" placeholder="手机号码" v-model="phone">
                    <p class="phone-tips" v-if="phoneTips">{{phoneTip}}</p>
                </div>
               
                <div class="select">
                    <div class="gread">
                        <Select v-model="grade" class="options" :placeholder="grade">
                                <Option v-for="item in greads" :value="item" :key="item">{{item}}</Option>
                            </Select>
                        <img src="../assets/images/选择按钮.png" alt="">
                    </div>
                    <div class="project">
                        <Select v-model="project" class="options" :placeholder="project">
                                <Option v-for="item in projects" :value="item" :key="item">{{item}}</Option>
                            </Select>
                        <img src="../assets/images/选择按钮.png" alt="">
                    </div>
                </div>
                <button @click="goConsultation">立即咨询</button>
            </div>
            <!-- <p class="description">
                <span>连续六年培养出</span> 高考满分学员
            </p> -->
        </div>
        <img class="success-tips" v-if="!current" src="../assets/images/alert.png" alt="">
        <!-- <img src="" v-else-if alt=""> -->
    </div>
</template>

<script>
import {consultation} from '../api/index.js';
import {cookie} from '../utils/index.js';
let timer = null;
export default {
    props: {
        showAppointment: {
            type: Boolean
        }
    },
    data() {
        return {
            isShow: true,
            current: true, 
            phoneTips: false,
            nameTips: false,
            name: '',
            phone: '',
            grade: '高一',
            project: '数学',
            greads: ['高一', '高二', '高三'],
            projects: ['数学', '语文', '英语', '物理', '化学', '生物', '地理', '历史', '政治'],
            phoneTip:'',
            nameTip: '',
            send: true
        };
    },
    watch: {
        name(val) {
            if(!val) {
                this.nameTips = true;
            }else{
                this.nameTips = false;
            }
            
        },
        phone(val) { 
            if(!val) {
                this.phoneTips = true;
            }else{
                this.phoneTip = '请输入手机号';
                this.phoneTips = false;
            }   
            
        }
    },
    created() {
        this.isShow = this.showAppointment;
    },
    methods: {
        goConsultation() {
            if(this.name === '') {
                this.nameTip = '请输入姓名';
                this.nameTips = true;
            }
            if(this.phone === '') {
                this.phoneTip = '请输入手机号';
                this.phoneTips = true;
            }
            // 调接口传值
            if(this.name !== '' && this.phone !== '' && this.send) {
                this.send = false;
                consultation({
                    name: this.name,
                    phone: this.phone,
                    grade: this.grade,
                    subject: this.project,
                    area: cookie.get('LINGSHI_AREA')
                }).then(res => {
                    if(res.code === 0) {
                        this.current = !this.current;
                        this.start();
                    }else {
                        this.send = true;
                        this.phoneTip = res.message; 
                        this.phoneTips = true;
                        if(res.code === 500) {
                            this.phoneTip = '服务器正在开小差,请稍后重试';
                            this.phoneTips = true;
                        } 
                    }
                }).catch(err => {
                    this.send = true;
                    this.phoneTip = '网络异常,请稍后重试';
                    this.phoneTips = true;
                });
            }
        },
        closeAppointment() {
            this.isShow = false;
        },
        start() {
            timer = setTimeout(() => {
                this.closeAppointment();
            }, 2000);
        }
    },
    destroyed () {
        clearTimeout(timer);
    },
};
</script>

<style lang="scss">
.appointment-wrap {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    .appointment-inner {
        width: 700px;
        margin-top: 140px;
        position: relative;
        .close {
            position: absolute;
            top: 22px;
            right: 24px;
            cursor: pointer;
        }
        .bg{
            width: 100%;
            height: auto;
            display: block;
        }
    }
    .appointment-container {
        position: absolute;
        top: 58px;
        left: 50px;
        .header {
            font-size: 34px;
            color: #696966;
        }
        .title {
            margin: 13px 0 20px;
            color: #0700c1;
            font-size: 24px;
        }
        .phone-wrap, .name-wrap {
            position: relative;
            margin-top: 20px;
        }
        .name-wrap input, .phone-wrap input {
            width: 310px;
            display: block;
            height: 40px;
            line-height: 40px;
            border: none;
            outline: none;
            border: 2px solid #d1d1d1;
            border-radius: 8px;
            text-indent: 8px;
        }
        .name-tips, .phone-tips{
            position: absolute;
            bottom: -20px;
            left: 6px;
            color: red;
        }
        .select {
            display: flex;
            justify-content: space-between;
        }
        .select .ivu-select .ivu-icon.ivu-select-arrow {
            display: none;
        }
        .ivu-select-selection {
            height: 40px;
            border: 2px solid #d1d1d1;
            .ivu-select-placeholder,
            .ivu-select-selected-value {
                height: 40px;
                line-height: 40px;
            }
        }
        .ivu-select-visible .ivu-select-selection{
            box-shadow: none;
        }
        .select .gread,
        .select .project {
            width: 152px;
            margin-top: 20px;
            position: relative;
        }
        .select .gread img,
        .select .project img {
            position: absolute;
            right: 10px;
            top: 10px;
        }
        button {
            border: 0;
            outline: none;
            width: 310px;
            margin-top: 34px;
            line-height: 40px;
            background: #0700c1;
            color: #ffffff;
            font-size: 17px;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .description {
        color: #0700c1;
        position: absolute;
        top: 120px;
        right: 160px;
        font-size: 20px;
        letter-spacing: 1px;
        span {
            display: block;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0;
        }
    }
    .success-tips{
        width: 600px;
        height: 400px;
         margin-top: 200px;
    }
}
</style>