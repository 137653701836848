/**  
 * @author xingminghui
 * @file 联系我们
 *  
 */

import * as types from '../type';
import {contact} from '../../api/index';
import {cookie} from '../../utils/index';
export const state = {
    school: {},
    group: {}
};
export const actions = {
    // 获取app下载
    async getContact({commit}, payload) {
        return await contact({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.status === 'success') {
                payload(res.data);
                commit(types.GET_CONTACT, res.data);
            }
            return res;
        }).catch(err => {
            console.log(err);
        }); 
    },
};
export const mutations = {
    // 获取app下载
    [types.GET_CONTACT](state, data) {
        state.school = data.school || {};
        state.group = data.group || {};
    }
};
