import Vue from 'vue';
import Router from 'vue-router';
import Index from './views/Index/index.vue';
import PEPCourse from './views/PEPCourse';
import OneToOne from './views/OneToOne';
import AutonomousEnrollment from './views/AutonomousEnrollment';
import APPDownload from './views/APPDownload';
import ContactUs from './views/ContactUs';
import TalentRecruitment from './views/TalentRecruitment';
import Privacy from './views/Privacy';
import Athletes from './views/Athletes';
import {cookie} from './utils/index';
Vue.use(Router);

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
        path: '/',
        redirect:'/index',
    }, {
        path:'/index',
        name: 'index',
        component: Index,
        meta: {
            title: '首页',
            id: 1
        }
    }, {
        path: '/PEPCourse',
        name: 'PEPCourse',
        component: PEPCourse,
        meta: {
            title: '培优课程',
            id: 2
        }
    }, {
        path:'/OneToOne',
        name:'OneToOne',
        component: OneToOne,
        meta: {
            title: '1对1',
            id: 3
        }
    }, {
        path: '/autonomousEnrollment',
        name: 'autonomousEnrollment',
        component: AutonomousEnrollment,
        meta: {
            title: '综评自招',
            id: 4
        }
    }, {
        path: '/APPDownload',
        name: 'APPDownload',
        component: APPDownload,
        meta: {
            title: 'APP下载',
            id: 5
        }
    }, {
        path: '/contactUs',
        name: 'contactUs',
        component: ContactUs,
        meta: {
            title: '联系我们',
            id: 6
        }
    }, {
        path: '/talentRecruitment',
        name: 'talentRecruitment',
        component: TalentRecruitment,
        meta: {
            title: '人才招聘',
            id: 7
        }
    }, {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
        meta: {
            title: '隐私保护',
            id: 10
        }
    }, {
        path: '/athletes',
        name: 'athletes',
        component: Athletes,
        meta: {
            title: '领世优动',
            id: 10
        }
    }],
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    }
});
router.beforeEach((to, from, next) => {
    if(to.meta.title) {
        document.title = to.meta.title;
    }
    cookie.set('LINGSHI_CATEGORIY_ID', to.meta.id);
    next();
});

export default router;
