/**  
 * @author xingminghui
 * @file 首页
 */

import * as types from '../type';
import {homePage} from '../../api/index';
import {cookie} from '../../utils/index';
export const state = {
    system: {},
    service: {},
    teacher: {},
    video: {},
    product: {}
};
export const actions = {
    // 获取 首页
    getHomePage({commit}, ...[options]) {
        homePage({area: options.area}).then(res => {
            if (res.status === 'success') {
                options.getData(res.data);
                commit(types.GET_HOME_PAGE, res.data);
            }else{
                commit(types.GET_HOME_PAGE, {});
            }
        }).catch(err => {
            console.log(err);
        }); 
    },
};
export const mutations = {
    // 获取 首页
    [types.GET_HOME_PAGE](state, data) {
        state.system = data.system || {};
        state.service = data.service || {};
        state.teacher = data.teacher || {};
        state.video = data.video || {};
        state.product = data.product || {};
    }
};
