/**
 * @file brower.js
 * @author xmh
 */
/* eslint-disable */
let browser,
    version,
    mobile,
    os,
    osversion,
    bit,
    ua = window.navigator.userAgent,
    platform = window.navigator.platform;

if (/MSIE/.test(ua)) {
    browser = 'Internet Explorer';
    if (/IEMobile/.test(ua)) {
        mobile = 1;
    }
    version = /MSIE \d+[.]\d+/.exec(ua)[0].split(' ')[1];
} else if (/Edge/.test(ua)) {
    // Platform override for Chromebooks
    browser = 'Edge';
} else if (/Chrome/.test(ua)) {
    // Platform override for Chromebooks
    if (/CrOS/.test(ua)) {
        platform = 'CrOS';
    }

    browser = 'Chrome';
    version = /Chrome\/[\d\.]+/.exec(ua)[0].split('/')[1];

} else if (/Opera/.test(ua)) {

    browser = 'Opera';

    if (/mini/.test(ua) || /Mobile/.test(ua)) {
        mobile = 1;
    }

} else if (/Android/.test(ua)) {

    browser = 'Android Webkit Browser';
    mobile = 1;
    os = /Android\s[\.\d]+/.exec(ua)[0];

} else if (/Firefox/.test(ua)) {

    browser = 'Firefox';

    if (/Fennec/.test(ua)) {
        mobile = 1;
    }
    version = /Firefox\/[\.\d]+/.exec(ua)[0].split('/')[1];

} else if (/Safari/.test(ua)) {

    browser = 'Safari';

    if ((/iPhone/.test(ua)) || (/iPad/.test(ua)) || (/iPod/.test(ua))) {
        os = 'iOS';
        mobile = 1;
    }

} else if (ua.indexOf("rv:11") > -1) {
    browser = 'Internet Explorer';
    version = '11';
}

if (!version) {

    version = /Version\/[\.\d]+/.exec(ua);

    if (version) {
        version = version[0].split('/')[1];
    } else {
        version = /Opera\/[\.\d]+/.exec(ua) && /Opera\/[\.\d]+/.exec(ua)[0].split('/')[1];
    }

}

// if (platform === 'MacIntel' || platform === 'MacPPC') {
//     os = 'Mac OS X';
//     osversion = /10[\.\_\d]+/.exec(ua)[0];
//     if (/[\_]/.test(osversion)) {
//         osversion = osversion.split('_').join('.');
//     }
// } else if (platform === 'CrOS') {
//     os = 'ChromeOS';
// } else if (platform === 'Win32' || platform == 'Win64') {
//     os = 'Windows';
//     bit = platform.replace(/[^0-9]+/, '');
// } else if (!os && /Android/.test(ua)) {
//     os = 'Android';
// } else if (!os && /Linux/.test(platform)) {
//     os = 'Linux';
// } else if (!os && /Windows/.test(ua)) {
//     os = 'Windows';
// }

export const UI = {
    browser: browser,
    version: version,
    mobile: mobile,
    os: os,
    osversion: osversion,
    bit: bit
};
/* eslint-enable */


