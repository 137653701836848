/**   
 * @file 接口
 * @author xingminghui
 */
import {
    get,
    post,
    delet
} from './http';
// 公共
export const areas = () => get('/areas'); // 获取地区列表

export const categories = () => get('/categories'); // 获取顶部导航栏

export const qrcode = (params) => get('/qrcode', params); // 获取在线咨询、官方微博二维码

export const banners = (params) => get('/banners', params); // 获取banner列表

export const links = (params) => get('/links', params); // 获取友情链接列表

export const footer = (params) => get('/footer', params); // 获取底部导航栏

// 首页
export const homePage = (params) => get('/homepage', params); // 获取首页

// 人才招聘
export const jobs = (params) => get('/jobs', params); // 获取人才招聘

// App下载
export const app = (params) => get('/app', params); // 获取App下载列表

// 1对1
export const oneToOne = (params) => get('/1v1', params); // 获取1对1列表
export const consultation = (params) => post('/consultation', params); // 发送咨询请求

// 综评自招
export const recruit = (params) => get('/recruit', params); // 获取综评自招列表
export const recruitSide = (params) => get('/recruit/qrcodes', params); // 获取侧边二维码

// 培优课程
export const senior = (params) => get('/course/senior', params); // 获取综评自招列表
export const junior = (params) => get('/course/junior', params); // 获取综评自招列表

// 领世优动
export const activity = params => get('/activity', params); // 获取介绍内容

// 联系我们
export const contact = (params) => get('/contact', params); // 获取综评自招列表

