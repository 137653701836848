/**
 *  @author xingminghui
 *  @file  公共接口
 */

import {qrcode, areas, links, footer, banners, categories} from '../../api/index';
import * as types from '../type';
import {cookie} from '../../utils/index';
export const state = {
    qrcode: null,
    categories: [],
    cityList: null,
    footer: null,
    links: null,
    banners: null,
    id: null,
    showPEPCourse: false // 浏览器自带的后退功能 是否 展示培优的初中 高中 不行！
};
export const actions = {
    // 获取在线咨询、官方微博二维码
    async getqrcode({commit}) {
        await qrcode({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.code === 0) {
                commit(types.QR_CODE, res.data);
            }
        }).catch(err => {
            console.log(err);
        });
    },
    async getareas({commit}, payload) {
        await areas().then(res => {
            if (res.code === 0) {
                payload(res.data);
                commit(types.GET_AREAS, res.data);
            } 
        }).catch(err => {
            console.log(err);
        }); 
    },
    async getFooter({commit}) {
        await footer().then(res => {
            if (res.code === 0) {
                commit(types.GET_FOOTER, res.data);
            }
        }).catch(err => {
            console.log(err);
        }); 
    },
    async getBanners({commit}) {
        await banners({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.code === 0) {
                commit(types.GET_BANNERS, res.data);
            } 
        }).catch(err => {
            console.log(err);
        }); 
    },
    async getLinks({commit}) {
        await links().then(res => {
            if (res.code === 0) {
                commit(types.GET_LINKS, res.data);
            } 
        }).catch(err => {
            console.log(err);
        }); 
    },
    async getCategories({commit}) {
        await categories({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.code === 0) {
                commit(types.GET_CATEGORIES, res.data);
            } 
        }).catch(err => {
            console.log(err);
        }); 
        // commit(types.GET_CATEGORIES, await categories().then(res => {
        //     if(res.code === 0) {
        //         return res.data;
        //     }
        // }));
    },
};
export const mutations = {
    // 获取在线咨询、官方微博二维码
    [types.QR_CODE](state, data) {
        state.qrcode = data || {};
    },
    [types.GET_AREAS](state, data) {
        state.cityList = data || {};
    },
    [types.GET_FOOTER](state, data) {
        state.footer = data || {};
    },
    [types.GET_BANNERS](state, data) {
        state.banners = data || {};
    },
    [types.GET_LINKS](state, data) {
        state.links = data || {};
    },
    [types.GET_CATEGORIES](state, data) {
        data.map(item => {
            switch(item.id) {
            case 1:
                item.path = '/index';
                break;
            case 2:
                item.path = '/PEPCourse';
                break;
            case 3:
                item.path = '/OneToOne';
                break;
            case 4:
                item.path = '/autonomousEnrollment';
                break;
            case 5:
                item.path = '/APPDownload';
                break;
            case 6:
                item.path = '/contactUs';
                break;
            case 7:
                item.path = '/talentRecruitment';
                break;
            case 10:
                item.path = '/athletes';
                break;
            }
            return item;
        });
        state.categories = data;
    },
    [types.SET_SHOWPEPCOURSE](state, data) { 
        state.showPEPCourse = data;
    },
    [types.SET_ID](state, data) {
        state.id = data;
    }
};
