<template>
    <div class="banner-wrap" v-if="bannersData.length>0">
        <Carousel v-if="bannersData.length>1" v-model="value" loop  
            :radius-dot='true' :autoplay='true' :arrow="arrow" id="banner">
            <CarouselItem v-for="(item, index) in bannersData" :key="index" 
            :style="styleObject"
            @click.native="goUrl(item.target_url)">
                <img class="demo-carousel" :src="item.img_url" alt="">
            </CarouselItem>
        </Carousel>
        <img v-else class="demo-carousel" :src="bannersData[0].img_url"
        @click="goUrl(bannersData[0].target_url)" alt="">
    </div>
</template>

<script>
import {Carousel} from 'iview';
let timeOut = null;
export default {
    components: {
        Carousel
    },
    props: {
        bannersData: {
            type: Array
        },
        height: {
            type: Number
        }
    },
    data() {
        return {
            value: 0,
            arrow: 'never',
            screenWidth: document.body.clientWidth, // 屏幕宽度
            timer: false,
            styleObject:{
                height: ''
            }
        };
    },
    created() {
        this.styleObject.height = this.height / (1920 / this.screenWidth) + 'px'; 
    },
    watch: {
        screenWidth (val) {
            if (!this.timer) {
                this.screenWidth = val;
                this.styleObject.height = this.height / (1920 / val) + 'px'; 
                this.timer = true;
                let that = this;
                timeOut = setTimeout(function () {
                    that.timer = false;
                }, 400);
            }
        }
    },
    methods: {
        goUrl(url) {
            if(url) {
                window.open(url, '_blank');
            }
        }
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth;
            })();
        };
    },
    destroyed() {
        clearTimeout(timeOut);
    }
};
</script>

<style lang="scss">
.banner-wrap{
    >img{
        width: 100%;
        height: auto;
        display: block;
    }
    #banner.ivu-carousel {
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        text-align: center;
        .ivu-carousel-dots.ivu-carousel-dots-inside {
            margin-bottom: 42px;
        }
        .ivu-carousel-dots.ivu-carousel-dots-inside li {
            margin: 0 12px;
        }
        .ivu-carousel-dots.ivu-carousel-dots-inside li.ivu-carousel-active button {
            width: 20px;
            border-radius: 10px;
            background-color: #fd5900;
        }
        .ivu-carousel-dots.ivu-carousel-dots-inside li button {
            width: 12px;
            height: 12px;
            background-color: #ffffff;
            opacity: 1;
        }
    }
}
</style>