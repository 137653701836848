<template>
    <div class="athletes">
        <!-- banner -->
        <carousel-banner :bannersData='bannersData'></carousel-banner>
        <!-- 内容 -->
        <div class="content">
            <img :src="introduce.img_url" :title="introduce.title" :alt="introduce.title"/>
        </div>
    </div>
</template>
<script>
import CarouselBanner from '../../components/banner.vue';
import {mapState, mapActions} from 'vuex';
import {cookie} from '../../utils/index.js';
import {activity} from '../../api/index';
export default {
    components: {
        CarouselBanner
    },
    data () {
        return {
            bannersData: [],
            introduce: {}
        };
    },
    computed: {
        ...mapState({
            banners: state => state.common.banners
        })
    },
    watch: {
        banners(val) {
            console.log(val, 'banner');
            let bannersItem = null;
            if(val) {
                let id = cookie.get('LINGSHI_CATEGORIY_ID');
                bannersItem = val.filter(item => {
                    return item.category_id + '' === id;
                });
                this.bannersData = bannersItem[0].banners || [];
            }
        }
    },
    created() {
        this.getBanners();
        this.getData();
    },
    methods: {
        ...mapActions(['getBanners']),
        getData () {
            activity({area: cookie.get('LINGSHI_AREA')}).then(res => {
                if (res.status === 'success') {
                    this.introduce = res.data.image;
                }
            }).catch(err => {
                console.log(err);
            }); 
        }
    }
};
</script>
<style lang="scss" scoped>
.content {
    img {
        width: 100%;
        vertical-align: top;
    }
}
</style>