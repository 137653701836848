<template>
    <div class="one-to-one" v-if="oneToOneList">
        <Appointment :showAppointment='showAppointment' />
        <carousel-banner :bannersData='bannersData' :height="440"></carousel-banner>
        <!-- 我们的学生都去了哪里 -->
        <div class="students-going" v-if="school">
            <div class="header" v-if="school.title">
                <p>{{school.subtitle}}</p>
                <h4>{{school.title}}</h4>
                <span></span>
            </div>
            <p class="describe" v-html="school.description"></p>
            <!-- 轮播 -->
            <div class="university-swiper" v-if="school.images">
                <div class="swiper-container" id="university">
                    <div class="swiper-wrapper">
                        <div v-for='(el, index) in school.images' :key='index' class="swiper-slide">
                            <img :src="el" alt="" class="book-photo">
                        </div>
                    </div>
                    <div class="swiper-button-prev">
                        <img src="../../assets/images/向左.png" alt="">
                    </div>
                    <div class="swiper-button-next next">
                        <img src="../../assets/images/向右.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 一对一学员高中全年成绩增趋势分析 -->
        <div class="score-analysis" v-if="analysis">
            <div class="inner" v-if="analysis.title">
                <div class="header">
                    <p>{{analysis.subtitle}}</p>
                    <h4>{{analysis.title}}</h4>
                    <span></span>
                </div>
            </div>
            <div class="score-wrapper" v-if="analysis.items">
                <div class="score-inner">
                    <div v-for="(item,index) in analysis.items" :key="index" class="score-item">
                        <img :src="item.img_url" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 我们的老师百分百称职，确保全部精力投入 -->
        <div class="full-energy" v-if="duty">
            <div class="inner" v-if="duty.title">
                <div class="header">
                    <p>{{duty.subtitle}}</p>
                    <h4>{{duty.title}}</h4>
                    <span></span>
                </div>
            </div>
            <img :src="duty.img_url" alt="">
            <!-- xxxx <img :src="duty.img_url" alt=""> -->
        </div>
        <!-- 优秀的成绩来自因材施教 -->
        <div class="supporting-materials" v-if="project">
            <div class="materials-inner" v-if="project.title">
                <div class="inner">
                    <div class="header">
                        <p>{{project.subtitle}}</p>
                        <h4>{{project.title}}</h4>
                        <span></span>
                        <div class="description">{{project.description}}</div>
                    </div>
                </div>
            </div>
            <img class="img-url" :src="project.img_url" alt="">
        </div>
        <!-- "优秀的成绩，来自严格的教学管理环节" -->
        <div class="note" v-if="note">
            <div class="note-inner" v-if="note.title">
                <div class="inner">
                    <div class="header">
                        <p>{{note.subtitle}}</p>
                        <h4>{{note.title}}</h4>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 优秀学员的笔记 -->
        <div class="correction" v-if="note.items">
            <div class="correction-swiper">
                <div class="swiper-container" id="swiper-correction">
                    <div class="swiper-wrapper"> 
                        <div v-for='(el, index) in note.items' :key='index' class="swiper-slide">
                            <div class="bg">
                                <img :src="el" alt="" class="correction-photo">
                            </div>
                        </div>
                    </div>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination"></div>
                </div>
                <div class="swiper-button-prev correction-prev">
                    <img src="../../assets/images/左.png" alt="">
                </div>
                <div class="swiper-button-next correction-next">
                    <img src="../../assets/images/右.png" alt="">
                </div>
            </div>
            <p>{{note.description}}</p>
        </div>
        <!-- 问题不拖延及时解答疑惑 -->
         <div class="wechat-picture" v-if="resolve.items && resolve.items.length">
            <wechat-picture :resolve="resolve.items"></wechat-picture>
            <div class="title">{{resolve.title}}</div>
            <div class="subtitle">{{resolve.description}}</div>
        </div>
        <!-- 配套服务教材 -->
        <div class="supporting-materials supporting">
            <div class="materials-inner" :style="{background: `url(${book.background_url}) center/cover  no-repeat`}">
                <div class="inner" v-if="book.title">
                    <div class="header">
                        <p>{{book.subtitle}}</p>
                        <h4>{{book.title}}</h4>
                        <span></span>
                    </div>
                    <div class="materials">
                        <img v-for="(item, index) in book.books" :key="index" :src="item" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 教学教材 -->
        <div class="teaching-materials" v-if="material">
            <div class="teaching-inner" v-if="material.title">
                <div class="inner">
                    <div class="header">
                        <p>{{material.subtitle}}</p>
                        <h4>{{material.title}}</h4>
                        <span></span>
                    </div>
                </div>
            </div>
            <div class="description">
                <img :src="material.img_url" alt="">
                <p>{{material.description}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import CarouselBanner from '../../components/banner.vue';
import Appointment from '../../components/appointment.vue';
import WechatPicture from '../../components/wechatPicture.vue';
import {cookie} from '../../utils/index.js';
import {mapState, mapActions} from 'vuex';
export default {
    components: {
        Appointment,
        WechatPicture,
        CarouselBanner
    },
    data() {
        return {
            showAppointment: true, // 显示弹层
            analysis: {},
            book: {},
            duty: {},
            material: {},
            note: {},
            project: {},
            resolve: {},
            school: {},
            bannersData: []
        };
    },
    created() {
        this.getOneToOne(res => {
            this.school = res.school;
            this.$nextTick(function() {
                this.createdSwiper('#university');
                this.correctionSwiper('#swiper-correction');
            });
        });
        this.getBanners();
    },
    watch: {
        oneToOneList(val) {
            this.analysis = val.analysis;
            this.book = val.book;
            this.duty = val.duty;
            this.material = val.material;
            this.note = val.note;
            this.project = val.project;
            this.resolve = val.resolve;
        },
        banners(val) {
            let bannersItem = null;
            if(val) {
                let id = cookie.get('LINGSHI_CATEGORIY_ID');
                bannersItem = val.filter(item => {
                    return item.category_id + '' === id;
                });
                this.bannersData = bannersItem[0].banners;
            }
        }
    },
    computed: {
        ...mapState({
            oneToOneList: state => state.oneToOne.oneToOneList,
            banners: state => state.common.banners
        })
    },  
    methods: {
        ...mapActions(['getOneToOne', 'getBanners']),
        createdSwiper(swiperName) {
            new Swiper(swiperName, {
                // loop: true,
                slidesPerView: 5,
                spaceBetween: 0,
                loopFillGroupWithBlank: true,
                // centeredSlides: true, // 页面居中
                // 如果需要分页器
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });
        },
        correctionSwiper(swiperName) {
            new Swiper(swiperName, {
                effect : 'coverflow',
                loop: true,
                slidesPerView: 2,
                slidesPerGroup: 1,
                spaceBetween: 0,
                centeredSlides: true,
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 600,
                    modifier: .8,
                    slideShadows: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.correction-next',
                    prevEl: '.correction-prev',
                }
            });
        },
    }
};
</script>

<style lang='scss'>
@import './oneToOne.scss';
</style>