/**  
 * @author xingminghui
 * @file APP下载
 */

import * as types from '../type';
import {app} from '../../api/index';
import {cookie} from '../../utils/index';
export const state = {
    appList: {}
};
export const actions = {
    // 获取app下载
    async getApp({commit}) {
        return await app({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.status === 'success') {
                commit(types.GET_APP, res.data);
            }else {
                commit(types.GET_APP, {});
            }
        }).catch(err => {
            console.log(err);
        }); 
    },
};
export const mutations = {
    // 获取app下载
    [types.GET_APP](state, data) {
        state.appList = data || {};
    }
};
