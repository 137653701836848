/**  
 * @author xingminghui
 * @file 
 */

import * as types from '../type';
import {jobs} from '../../api/index';
import {cookie} from '../../utils/index';
export const state = {
    jobs: []
};
export const actions = {
    // 获取在线咨询、官方微博二维码
    // {area: cookie.get('LINGSHI_AREA')}
    async getJobs({commit}, options) {  
        return await jobs(options).then(res => {
            if (res.status === 'success') {
                commit(types.GET_JOBS, res.data);
            }else {
                commit(types.GET_JOBS, []);
            }
        }).catch(err => {
            console.log(err);
        }); 
    },
};
export const mutations = {
    // 获取在线咨询、官方微博二维码
    [types.GET_JOBS](state, data) {
        state.jobs = data;
    }
};
