/**
 * @file
 * @author xingminghui
 */

// 公共 
export const QR_CODE = 'QR_CODE'; // 获取在线咨询、官方微博二维码

export const GET_AREAS = 'GET_AREAS'; // 获取地区列表

export const GET_FOOTER = 'GET_FOOTER'; // 获取底部导航栏

export const GET_BANNERS = 'GET_BANNERS'; // 获取banner列表

export const GET_LINKS = 'GET_LINKS'; // 获取友情链接列表

export const GET_CATEGORIES = 'GET_CATEGORIES'; // 获取顶部导航栏

export const SET_ID = 'SET_ID'; // 设置导航id
export const SET_SHOWPEPCOURSE = 'SET_SHOWPEPCOURSE';  

// export const SET_CITY = 'SET_CITY'; // 设置当前城市


// 首页
export const GET_HOME_PAGE = 'GET_HOME_PAGE';

// 人才招聘
export const GET_JOBS = 'GET_JOBS';

// app下载
export const GET_APP = 'GET_APP';

// 1对1
export const GET_ONE_TO_ONE = 'GET_ONE_TO_ONE'; // 获取1对1列表
export const POST_CONSULTATION = 'POST_CONSULTATION'; // 发送咨询请求

// 获取综评自招列表
export const GET_RECRUIT = 'GET_RECRUIT'; 
export const GET_QRCODE = 'GET_QRCODE'; // 获取侧边二维码

// 培优课程
export const GET_SENIOR = 'GET_SENIOR'; // 获取高中课程
export const GET_JUNIOR = 'GET_JUNIOR'; // 获取初中课程
export const CHANGE_CURRENT_GRADE = 'CHANGE_CURRENT_GRADE'; // 修改初高中

// 联系我们
export const GET_CONTACT = 'GET_CONTACT';

 