<template>
    <div v-if="item" id="container-item"  :class="[{'order':item.image_position===2, 
    'white': colorWhite}]" :style="styleObject">
        <div class="list-item clearfix">
            <img :src="item.img_url" alt="">
            <div class="container">
                <div class="title clearfix">
                    <img  v-if="isTitle" :src="require(`../assets/images/${icon}.png`)" alt="">
                    <span >{{item.title}}</span>
                </div>
                <p >{{item.description}}</p>
                <button v-if='buy' @click="showCode" :class="[{'buy':item.image_position===2}]">购买</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item', 'buy'],
    data() {
        return {
            styleObject: {},
            colorWhite: false,
            icon: '蓝色对勾',
            isTitle: true
        };
    },
    watch: {
        item(val) {
            // Object.prototype.hasOwnProperty.call(obj, 'name')
            if(Object.prototype.hasOwnProperty.call(val, 'background_url') && val.background_url) {
                this.styleObject.background = `url(${val.background_url}) center/cover  no-repeat`;
                this.colorWhite = true;
                this.icon = '选择框';
            }
            if(!Object.prototype.hasOwnProperty.call(val, 'title') || !val.title) {
                this.isTitle = false;
            }
        }
    },
    created() {
        if(Object.prototype.hasOwnProperty.call(this.item, 'background_url') && this.item.background_url) {
            this.styleObject.background = `url(${this.item.background_url}) center/cover  no-repeat`;
            this.colorWhite = true;
            this.icon = '选择框';
        }
        if(!Object.prototype.hasOwnProperty.call(this.item, 'title') || !this.item.title) {
            this.isTitle = false;
        }
    },
    methods: {
        showCode() {
            this.$emit('showQrcode');
        }
    }
};
</script>

<style lang="scss" scoped>
    #container-item{
        padding: 36px 0;
    }
    .list-item{
        width: 1038px;
        margin: 0 auto;
        position: relative;
    }
    .list-item>img{
        float: left;
    }
    .list-item .container{
        margin: 112px 0 0 84px;
        float: left;
    }
    .list-item .title{
        width: 500px;
    }
    .list-item .title img{
        float: left;
    }
    .list-item .title span{
        width: 400px;
        float: left;
        font-size: 30px;
        color: #333333;
        margin-left: 14px;
        letter-spacing: 2px;
        color: #051294;
    }
    .list-item .container p{
        width: 410px;
        font-size: 18px;
        color: #666666;
        margin-top: 38px;
        line-height: 36px;
        padding-left: 58px;
        color: #051294;
    }
    #container-item.order .container{
        float: left;
        margin-left: 0;
    }
    #container-item.order .list-item>img{
        float: right;
    }
    #container-item.white {
        .list-item .container p,.list-item .title span{
            color: #ffffff;
        }
    }
    button{
        outline: none;
        margin-left: 54px;
        width: 340px;
        line-height: 36px;
        border: none;
        text-align: center;
        margin-top: 20px;
        border: 1px solid #9c9fd4;
        border-radius: 8px;
        color: #051294;
        font-size: 18px;
        cursor: pointer;
    }
    button.buy{
        color: #ffffff;
        border: 1px solid #ffffff;
        background: rgba(255, 255, 255, .2);
    }
</style>