/**
 * @file
 * @author xingminghui
 */

import Vue from 'vue';
import Vuex from 'vuex';
import 'es6-promise/auto';

import * as common from './modules/common.js';
import * as homePage from './modules/homePage.js';
import * as talentRecruitment from './modules/talentRecruitment.js';
import * as appDownload from './modules/appDownload.js';
import * as oneToOne from './modules/oneToOne';
import * as autonomousEnrollment from './modules/autonomousEnrollment';
import * as PEPCourse from './modules/PEPCourse';
import * as contactUs from './modules/contactUs';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
    modules: {
        common,
        homePage,
        talentRecruitment,
        appDownload,
        oneToOne,
        autonomousEnrollment,
        PEPCourse,
        contactUs
    },
    strict: debug
});