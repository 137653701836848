<template>
    <div class="teachers-inner" v-if="teachersData">
        <div class="teachers clearfix" ref="teachers">
            <div class="left clearfix">
                <div class="subject-arrow" v-if="teachersData">
                    <div class="subject swiper-container">
                        <div class="swiper-wrapper" ref="subject" >
                            <template v-for="(item, index) in teachersData" >
                                <div  :key="item.id" 
                                    v-if="item.teachers && item.teachers.length"
                                    class="swiper-slide subject-item"
                                    @click="checkSubject(index)" 
                                    :style="styleObject"
                                    :class="[currentSubject===index?'current-subject':'']">
                                    <div class="icon">
                                        <img :src="[currentSubject===index?currentIcon:item.icon]" alt="">
                                    </div>
                                    <span>{{item.name}}</span>
                                </div>
                            </template>
                        </div>
                        <div class="swiper-button-prev subject-item swiper-subject-prev">
                            <img src="../assets/images/向上框.png" alt="">
                        </div>
                        <div class="swiper-button-next subject-item swiper-subject-next">
                            <img src="../assets/images/向下框.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="set-arrow" v-if="teachersData">
                    <div class="swiper-container teacher-name" >
                        <div class="swiper-wrapper group">
                            <div class="swiper-slide teacher" 
                            v-for="(item, index) in teachers" 
                            :key="index" @click="checkTeacher(index)" 
                            :class="[currentTeacher===index?'current-teacher':'']">
                                <div>{{item.name}}<span></span></div>
                            </div>
                        </div>
                        <div class="swiper-button-prev teacher swiper-teacher-prev" >
                            <img src="../assets/images/向上框.png" alt="">
                        </div>
                        <div class="swiper-button-next teacher swiper-teacher-next">
                            <img src="../assets/images/向下框.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="teacher-msg">
                <div class="teacher-picture">
                    <img :key="teachersData[currentSubject].teachers[currentTeacher].img_url" 
                    v-lazy="teachersData[currentSubject].teachers[currentTeacher].img_url" > 
                </div>
                <div class="details">
                    <img src="../assets/images/img_11.png" alt="">
                    <div class="name">{{teachersData[currentSubject].teachers[currentTeacher].name}}
                        <span>{{teachersData[currentSubject].teachers[currentTeacher].spell_name}}</span>
                    </div>
                    <div class="text" ref="textContainer">
                        <p v-for="(item, index) in description" :key="index">
                            {{item}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
let mySwiper = null;
let myObjectSwiper = null;
export default {
    props: ['teachersData'],
    data() {
        return {
            currentSubject: 0,
            currentTeacher: 0,
            description: [],
            teachers: [],
            currentIcon: null,
            styleObject:{
                height: ''
            }

        };
    },
    created() {
        this.currentSubject = 0;
        const icon = this.teachersData[this.currentSubject].icon;
        const arr = icon.split('.png');
        arr[1] = '-bg.png';
        this.currentIcon = arr.join('');
        this.teachers = this.teachersData[this.currentSubject].teachers;
        let descriptions = this.teachersData[0].teachers[0].description;
        this.changeDescription(descriptions);
    },
    watch: {
        teachersData(val) {
            this.currentSubject = 0;
            // 更改icon-url
            const icon = this.teachersData[this.currentSubject].icon;
            const arr = icon.split('.png');
            arr[1] = '-bg.png';
            this.currentIcon = arr.join('');
            this.teachers = this.teachersData[this.currentSubject].teachers;
            let descriptions = this.teachersData[0].teachers[0].description;
            this.changeDescription(descriptions);
        }
    },
    methods: {
        checkSubject(index) {
            this.$refs.textContainer.scrollTop = 0;
            this.currentSubject = index;
            // 更改icon-url
            const icon = this.teachersData[index].icon;
            const arr = icon.split('.png');
            arr[1] = '-bg.png';
            this.currentIcon = arr.join('');
            this.currentTeacher = 0;
            this.description = [];
            this.teachers = this.teachersData[index].teachers;
            let descriptions = this.teachersData[index].teachers[0].description;
            this.$nextTick(function() {
                this.changeDescription(descriptions);
                setTimeout(() => {
                    mySwiper.setTranslate(0);
                    mySwiper.update(true);
                    mySwiper.slideTo(0);
                }, 50);
            });
        },
        checkTeacher(index) {
            this.$refs.textContainer.scrollTop = 0;
            this.description = [];
            this.currentTeacher = index;
            let descriptions = this.teachersData[this.currentSubject].teachers[index].description;
            this.changeDescription(descriptions);
        },
        changeDescription(descriptions) {
            let enter = descriptions.match(/[\r\n]/g);
            if(enter && enter.length) {
                descriptions.split(enter[0]).forEach(element => {
                    if(element !== "") {
                        this.description.push(element);
                    }
                });
            }else{
                this.description.push(descriptions);
            }
        },
        createdSwiper() {
            mySwiper = new Swiper('.teacher-name', {
                watchOverflow: true,
                initialSlide: 0,
                direction : 'vertical',
                slidesPerView: 'auto',
                observer: true, // 修改swiper自己或子元素时，自动初始化swiper 
                observeParents: true,
                // observeSlideChildren: true,
                // 如果需要分页器
                navigation: {
                    nextEl: '.swiper-teacher-next',
                    prevEl: '.swiper-teacher-prev',
                }
            });
        },
        createdSubjectSwiper(perViewNum) {
            let _this = this;
            myObjectSwiper = new Swiper('.subject', {
                watchOverflow: true, // 内容未满时不开启swiper
                initialSlide: 0,
                direction : 'vertical',
                slidesPerView: perViewNum,
                autoHeight: true,
                observer: true, // 修改swiper自己或子元素时，自动初始化swiper 
                // 如果需要分页器
                navigation: {
                    nextEl: '.swiper-subject-next',
                    prevEl: '.swiper-subject-prev',
                }
            });
        }
    },
    mounted() {
        let length = this.$refs.subject.children.length;
        if(length > 5) {
            this.createdSubjectSwiper(5);
        }else {
            this.createdSubjectSwiper(length);
        }
        this.createdSwiper();
    }
};
</script>

<style lang="scss">
    // 师资团队
    .teachers-inner{
        width: 960px;
        margin: 70px auto;
         @media screen and (max-width: 1680px){
            width: 900px;
        }
        .teachers{
            background: #ffffff;
            border: 1px solid #cbcbe2;
            box-shadow: 0px 10px 8px #cbcbe2;
            height: 700px;
            // overflow: hidden;
            @media screen and (max-width: 1680px){
                height: 560px;
            }
        }
        .left {
            float: left;
        }
        .left .subject-arrow{
            height: 700px;
            float: left;
            border-right: 4px solid #fb6300;
            position: relative;
            // overflow: hidden;
            @media screen and (max-width: 1680px){
                height: 560px;
            }
        }
        .left .subject.swiper-container{
            width: 112px;
            height: 700px;
            // overflow: hidden;
            position: static;
            @media screen and (max-width: 1680px){
                height: 560px;
                position: static;
            }
        }
        .left .subject.swiper-container .swiper-wrapper{
            width: 100%;
            height: 100%;
        }
        .left .subject.swiper-container .subject-item {
            // display: block;
            width: 112px;
            height: 139px;
            text-align: center;
            color: #2f37ac;
            padding-bottom: 14px;
            background: url('../assets/images/科目阴影.png') no-repeat center;
            background-size: cover;
            padding-top: 21px;
            cursor: pointer;
            @media screen and (max-width: 1680px){
                padding-top: 14px;
                height: 111px;
            }
        }
        .left .subject.swiper-container .subject-item span{
            font-size: 20px;
            @media screen and (max-width: 1680px){
                font-size: 18px;
            }
        }
        .left .subject.swiper-container .subject-item .icon{
            width: 60px;
            height: 60px;
            margin: 0 auto 14px;
            position: relative;
            @media screen and (max-width: 1680px){
                width: auto;
                height: auto;
                margin: 0 auto 6px;
            }
            img{
                @media screen and (max-width: 1680px){
                    width: 45px;
                    height: 45px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        /* 选中的科目*/
        .left .subject.swiper-container .swiper-slide.current-subject{
            position: relative;
            &:after{
                content: '';
                display: block;
                width: 0;
                height: 0;
                border:20px solid #fc5d00;
                border-color: transparent #fc5d00 transparent transparent ;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        .set-arrow{
            width: 100px;
            position: relative;
            float: left;
            .swiper-slide{
                height: 70px;
                @media screen and (max-width: 1680px){
                    height: 56px;
                }
                cursor: pointer;
            }
        }
        .left .teacher-name{
            width: 100px;
            height: 700px;
            line-height: 70px;
            text-align: center;
            position: static;
            @media screen and (max-width: 1680px){
                height: 560px;
                line-height: 56px;
            }
        }
        .left .teacher-name .teacher{
            box-sizing: border-box;
            background: url('../assets/images/老师名字阴影.png') no-repeat center;
            background-size: cover;
        }
        .left .teacher-name .teacher div{
            font-size: 16px;
            height: 70px;
            width: 50px;
            text-align: justify;
            margin: 0 auto;
            color: #2011aa;
            @media screen and (max-width: 1680px){
                height: 56px;
            }
        }
        .left .teacher-name .teacher div span{
            display: inline-block /* Opera */;
            padding-left: 100%;
        }
       .left .teacher-name .current-teacher div{
           color: #ffffff;
       }
        .left .teacher-name .current-teacher{
            position: relative;
            background: none;
            background-color: #fe5f01;
            &::before{
                content: '';
                display: block;
                width: 0;
                height: 0;
                border:16px solid #ffffff;
                border-color: transparent #ffffff transparent transparent ;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .teacher-msg{
            float: right;
            color: #2011aa;
            box-sizing: border-box;
            .teacher-picture{
                float: left;
                background: #cbcbe2;
                margin: 70px 0 0 50px;
                width: 380px;
                height: 580px;
                img{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
                img[lazy="loading"] {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
                @media screen and (max-width: 1680px){
                    margin: 60px 20px 0 0;
                    height: 464px;
                    width: 304px;
                }
            }
            .details{
                position: relative;
                float: left;
            }
            
            .details img{
                height: auto;
                width: 300px;
                position: absolute;
                top: 0;
                right: 0;
                @media screen and (max-width: 1680px){
                    width: 300px;
                }
            }
            .details .text {
                width: 312px;
                margin-top: 220px;
                letter-spacing: 1px;
                text-align:justify; 
                padding: 0 50px; 
                height: 440px;
                overflow: auto;
                @media screen and (max-width: 1680px){
                    // width: 378px;
                    width: 312px;
                    height: 290px;
                    margin-top: 230px;
                }
            }
            .details .text p{
                font-size: 16px;
                line-height: 36px;
                text-indent: 32px;
            }
            .details .name{
                width: 134px;
                position: absolute;
                top: 128px;
                right: 86px;
                text-align: center;
                margin-bottom: 70px;
                font-size: 16px;
                font-weight: bold;
                @media screen and (max-width: 1680px){
                    width: 130px;
                    top: 124px;
                }
            }
            .details .name span{
                display: block;
            }
        }
    }
    // 修改 科目 swiper 箭头
    .subject {
        .swiper-button-next{
            width: 112px;
            height: 139px;
            background-image: none; 
            margin-top: 0;
            position: absolute;
            top: 560px;
            left: 0;
            img{
                width: 37px;
                height: 22px;
                margin-top: 40px;
            }
            @media screen and (max-width: 1680px){
                top: 448px;
            }
        }
        .swiper-button-prev{
            width: 112px;
            height: 139px;
            background-image: none; 
            margin-top: 0;
            position: absolute;
            top: 0;
            left: 0;
            img{
                width: 43px;
                height: 25px;
                margin-top: 30px;
            }
        }
    }
    .swiper-button-next.subject-item.swiper-button-disabled,
    .swiper-button-next.teacher.swiper-button-disabled{
        display: none;
    }
    .swiper-button-prev.subject-item.swiper-button-disabled,
    .swiper-button-prev.teacher.swiper-button-disabled{
        display: none;
    }
    //  修改 教师swiper 箭头
    .teacher-name{
        .swiper-button-prev{
            width: 100px;
            height: 70px;
            margin-top: 0;
            position: absolute;
            top: 0;
            left: 0;
            @media screen and (max-width: 1680px){
                height: 56px;
            }
        }
        .swiper-button-next{
            width: 100px;
            height: 68px;
            margin-top: 0;
            position: absolute;
            top: 630px;
            left: 0;
            @media screen and (max-width: 1680px){
                height: 56px;
                top: 504px;
            }
        }
    }


</style>