/**  
 * @author xingminghui
 * @file 综评自招
 */

import * as types from '../type';
import {recruit, recruitSide} from '../../api/index';
import {cookie} from '../../utils/index';
export const state = {
    recruitList: {},
    online: {},
    sideQRcode: {}
};
export const actions = {
    // 获取综评自招列表
    async getRecruit({commit}, payload) {
        return await recruit({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.status === 'success') {
                payload(res.data);
                commit(types.GET_RECRUIT, res.data);
            }
        }).catch(err => {
            console.log(err);
        }); 
    },
    // 获取侧边二维码
    async getQRcode({commit}, payload) {
        return await recruitSide({area: cookie.get('LINGSHI_AREA')}).then(res => {
            if (res.status === 'success') {
                commit(types.GET_QRCODE, res.data);
            }
        }).catch(err => {
            console.log(err);
        }); 
    },
};
export const mutations = {
    // 获取综评自招列表
    [types.GET_RECRUIT](state, data) {
        state.recruitList = data || {};
        state.online = data.online || {};
    },
    // 获取侧边二维码
    [types.GET_QRCODE](state, data) {
        state.sideQRcode = data || {};
    }
};
