<template>
    <div class="PEP-course">
        <div class="option" v-if="schoolData[0]">
            <div :class="[currentIndex===1?'option-item junior-item':'option-item']">
                <span
                @click="checkGrade(item.id, index)"
                v-for="(item, index) in schoolData[0].sub_categories"
                :key="item.id"
                :class="[currentGrade===item.id?'current':'']"
                >{{item.name}}</span>
            </div>
        </div>
        <high-school v-if="highSchool" :currentId=currentGrade></high-school>
        <middle-school v-if="!highSchool" :currentId=currentGrade></middle-school>
    </div>
</template>

<script>
import HighSchool from '../../components/highSchool';
import MiddleSchool from '../../components/middleSchool';
import {cookie} from '../../utils/index.js';
import {mapState, mapActions} from 'vuex';
export default {
    components: {
        HighSchool,
        MiddleSchool
    },
    data() {
        return {
            highSchool: true,
            schoolData: [], 
            currentIndex: 0 // 设置初高中位置
        };
    },
    created() {
        this.schoolData = this.categories.filter(element => {
            return element.sub_categories;
        });
        if(this.currentGrade === 8) {
            this.highSchool = true;
            this.currentIndex = 0;
        }else{
            this.highSchool = false;
            this.currentIndex = 1;
        }
    },
    watch: {
        currentGrade(val) {
            if(val === 8) {
                this.highSchool = true;
            }else{
                this.highSchool = false;
            }
        },
        categories(val) {
            this.schoolData = val.filter(element => {
                return element.sub_categories;
            });
        }
    },
    computed: {
        ...mapState({
            currentGrade: state => state.PEPCourse.currentGrade,
            categories: state => state.common.categories
        })
    },
    methods: {
        ...mapActions(['changeGrade']),
        checkGrade(grade, index) {
            this.currentIndex = index;
            window.scrollTo(0, 0);
            this.changeGrade(grade);
        }
    }
};
</script>

<style lang="scss">
 @import './PEPCourse.scss';
</style>