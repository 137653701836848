<template>
    <div id="talent-recruitment">
        <Collapse v-model="defaultValue" id="collapse" v-if="jobs[0]">
            <Panel :name="index+1+''" ref="collapse" v-for="(item, index) in jobs" :key="index">
                <div class="title" > 
                    <div class="title-name">
                        <span class="spell">TEACHERS OF VARIOUS SUBJECTS</span>
                        <h4>{{item.name}}</h4>
                        <span class="line"></span>
                        <div class="address">
                            <div>
                               <a @click.stop :href="'mailto:'+item.email"
                               rel="noopener noreferrer">简历投递至：{{item.email}}</a>
                            </div>
                            <div><a @click.stop :href="'mailto:'+item.email">{{item.remark}}</a></div>
                        </div>
                    </div>
                    <img :src="require(`../../assets/images/${defaultValue.indexOf(index+1+'')
                    =='-1'?'hide':'show'}.png`)" alt="">
                </div>
                <div slot="content" class="content" v-for="(val, index) in item.items" :key="index">
                    <img :src="require('../../assets/images/post.png')" alt="">
                    <div class="option-name">{{val.name}}</div>
                    <p v-for="(values,idx) in val.contents" :key="idx">{{values}}</p>
                </div>
            </Panel>
        </Collapse>
    </div>
</template>

<script>
import {Collapse} from 'iview';
import {mapState, mapActions} from 'vuex';
import {cookie} from '../../utils/index';
export default {
    name: '',
    components: {
        Collapse
    },
    data() {
        return {
            defaultValue: '1'
        };
    },
    created() {
        this.getJobs({area: cookie.get('LINGSHI_AREA')});
    },
    computed: {
        ...mapState({
            jobs: state => state.talentRecruitment.jobs
        })
    },
    methods: {
        ...mapActions(['getJobs'])
    }
};
</script>

<style lang="scss">
@import './TalentRecruitment.scss';
</style>