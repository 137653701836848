<template>
    <div class="wechat-swiper">
        <div class="swiper-container" id="swiper-wechat">
            <div class="swiper-wrapper">
                <div v-for='(el, index) in resolve' :key='index' class="swiper-slide">
                    <img class="pic" :src="el" alt="">
                    <img src="../assets/images/iPhoneX.png" class="bg" alt="">
                    <img class="black-left" src="../assets/images/黑蒙版左.png" alt="">
                    <img class="black-right" src="../assets/images/黑蒙版右.png" alt="">
                    <img class="left" src="../assets/images/蒙版左.png" alt="">
                    <img class="right" src="../assets/images/蒙版右.png" alt="">
                </div>
            </div>
            <div class="swiper-button-prev wechat-prev">
                <img src="../assets/images/左.png" alt="">
            </div>
            <div class="swiper-button-next wechat-next">
                <img src="../assets/images/右.png" alt="">
            </div>
        </div>
        
    </div>
</template>

<script>
import Swiper from 'swiper';
export default {
    props: ['resolve'],
    data() {
        return {
            resolveList: []
        };
    }, 
    created() {

    },
    watch: {
        resolve(val) {
            this.resolveList = val;
            this.$nextTick(function() {
                this.wechatSwiper('#swiper-wechat');
            });
        }
    },
    methods: {
        wechatSwiper(name) {
            new Swiper(name, {
                effect : 'coverflow',
                // autoplay: true,
                loop: true,
                slidesPerView: 2.2,
                // slidesPerGroup: 1,
                spaceBetween: 0,
                centeredSlides: true,
                // loopFillGroupWithBlank: true,
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 300,
                    modifier: 1,
                    slideShadows: false
                },
                navigation: {
                    nextEl: '.wechat-next',
                    prevEl: '.wechat-prev',
                }
            });
        }
    },
    mounted() {
        this.wechatSwiper('#swiper-wechat');
    }
};
</script>

<style lang="scss">
 #swiper-wechat .swiper-button-prev{
    background-image: none;
    left: 80px;
}
 #swiper-wechat .swiper-button-next{
    background-image: none; 
    right: 120px;
}
.wechat-swiper{
    width: 100%;
    .swiper-wrapper .swiper-slide img.pic{
        width: 80%;
        height: 680px;
        @media screen and (max-width: 1680px){
            width: 69%;
            height: 490px;
        }
    }
    .swiper-wrapper .swiper-slide-prev img.pic{
        @media screen and (max-width: 1680px){
            margin-left: 16%;
        }
    }
    .swiper-wrapper .swiper-slide-next img.pic{
        @media screen and (max-width: 1680px){
            margin-right: 16%;
        }
    }
    .swiper-slide{
        position: relative;
         @media screen and (max-width: 1680px){
            padding: 16px 0;
        }
        .left, .right, .black-left, .black-right{
            display: none;
        }
    }
    .swiper-wrapper .swiper-slide-active img.pic{
        width: 74%;
        height: 700px;
        padding-top: 20px;
        @media screen and (max-width: 1680px){
            width: 66%;
            height: 474px;
            padding-top: 0;
        }
    }
    .swiper-wrapper img.bg{
        width: 86%;
        height: 706px;
        position: absolute;
        top: 0;
        left: 30px;
        display: none;
         @media screen and (max-width: 1680px){
            width: 76%;
            height: 500px;
            left: 44px;
            top: 2px;
        }
    }
    .swiper-wrapper .swiper-slide-active img.bg{
        display: block;
    }
    .swiper-wrapper .swiper-slide-prev .left,
    .swiper-wrapper .swiper-slide-prev .black-left{
        display: block;
        width: 78%;
        height: 680px;
        position: absolute;
        top: 0;
        left: 54px;
        z-index: 20;
        @media screen and (max-width: 1680px){
            width: 75%;
            left: 64px;
            height: 494px;
            top: 14px
        }
    }
    .swiper-wrapper .swiper-slide-next .right,
    .swiper-wrapper .swiper-slide-next .black-right{
        display: block;
        height: 680px;
        position: absolute;
        top: 0;
        left: 43px;
        z-index: 20;
        width: 78%;
        @media screen and (max-width: 1680px){
            width: 75%;
            left: 26px;
            height: 494px;
            top: 16px
        }
    }
}

</style>